import { useState } from "react"
import Loader from "../components/icons/Loader"
import Err from "../components/auth/Err";
export default function AccountDeletion() {
    const [password, setPassword] = useState('');
    const [submit, setSubmit] = useState(false)
    const [resErr, setResErr] = useState({ message: "" });
    const [data, setDate] = useState({
        email: "",
        password: "",
        name: "",
        phone: "",
        reason: ""
    })
    function handleChange(e) {
        setDate({ ...data, [e.target.name]: e.target.value })
    }
    async function handleSumbit(e) {
        console.log(data)
        e.preventDefault();
        setSubmit(true);
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/Authenticate/accountDeletion', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ password: password })
            });
            const result = await response.json();
            if (response.status == 200) {
                localStorage.clear();
                window.location.assign('/');
            }
            else {
                throw result
            }
        } catch (err) {
            setSubmit(false);
            setResErr({ message: err.message })
        }
    }
    return (
        <div style={{ maxWidth: '800px' }} className="m-a sm-p-1">
            <form onSubmit={handleSumbit}>
                <h1>Account Deletion Request Form</h1>
                <div>
                    <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n" type="email" name="email" placeholder="Email" required />
                    <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n" type="text" name="yourName" placeholder="Your name" required />
                    <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n" type="password" name="password" placeholder="Password" required />
                    <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n" type="text" name="phone" placeholder="Phone(optional)" />
                    {/* <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n" type="text" name="userName" placeholder="User Name(optional)" /> */}
                    <div className="mt-1">
                        <p>Reason for Account Deletion</p>
                        <div>
                            <div>
                                <input id="1" onChange={handleChange} style={{ width: "20px", height: "20px" }} className="mr-1" type="radio" name="reason" value='1' />
                                <label htmlFor="1" className="sm-fs-14px pointer">I no longer wish to use this service.</label>
                            </div>
                            <div>
                                <input id="2" onChange={handleChange} style={{ width: "20px", height: "20px" }} className="mr-1" type="radio" name="reason" value='2' />
                                <label htmlFor="2" className="sm-fs-14px pointer">I have no concerns about my privacy.</label>
                            </div>
                            <div>
                                <input id="3" onChange={handleChange} style={{ width: "20px", height: "20px" }} className="mr-1" type="radio" name="reason" value='3' />
                                <label htmlFor="3" className="sm-fs-14px pointer">Other(Please specify):</label>
                                <input onChange={handleChange} style={{ padding: "14px 24px" }} className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n pointer" type="text" name="specifyReason" placeholder="Please specify the reason" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="txt-end mt-2">
                    <button className="btn pointer">Delete Account</button>
                </div> */}
                <div className="d-f mt-2 c-gap-2 f-j-e">
                    <button onClick={() => window.location.assign('/account')} className="btn pointer">Cancel</button>
                    <button className="p-r br-btn pointer" style={{ width: "171px" }} type="submit" >Delete Account {submit && <Loader color={'white'} />}</button>
                </div>
            </form>
            {resErr.message && <div className="overlay"><Err data={resErr} click={() => setResErr({ message: "" })} /></div>}
        </div>
    )
}