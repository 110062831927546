import { postData, getData } from "./utils";
export async function reserve(params) {
    const url = process.env.REACT_APP_DOAMIN + '/booking/Reserving';
    const data = await postData(url, params);
    if (data) return data;
    return '';

}
export async function creditReserve(params) {
    const url = process.env.REACT_APP_DOAMIN + '/booking/creditReserve';
    const data = await postData(url, params);
    if (data) return data;
    return '';

}
export async function getPaxDetail(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Pax/viewDetails';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
export async function getPax(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Pax/getPax';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
export async function storePax(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Pax/storePaxDetails';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
export async function cancelReserving(params) {
    const url = process.env.REACT_APP_DOAMIN + '/booking/cancelBooking';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
export async function cancelBooking(params) {
    const url = process.env.REACT_APP_DOAMIN + '/booking/Refund';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
export async function getMeals(params, bookingId) {
    const url = process.env.REACT_APP_DOAMIN + '/Menu/getMenu?travelDate=' + params.split('-').reverse().join('/') + '&cCode=' + bookingId[0] + '&dCode=' + bookingId[1] + '&year=' + bookingId[2] + '&sNo=' + bookingId[3];
    const data = await getData(url);
    if (data) return data;
    return '';
}

// extra luggage
export async function extraLuggagePrice(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Menu/getExtraLuggagePrice';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}

export async function getCreditLimit(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Dashboard/creditLimitAmount';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}

export async function getSeats(params) {
    const url = process.env.REACT_APP_DOAMIN + '/seatSelection/seatList';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}

export async function updateSeats(params) {
    const url = process.env.REACT_APP_DOAMIN + '/seatSelection/seatUpdate';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}
// curl "ipinfo.io/123.231.123.152?token=f798e6830180ff"