import './App.css';
// import './styles.css';
// import './nav-footer-res.css';
// import './animation.css';
import './assets/css/mobile.css';
import './assets/css/tab.css';
import './assets/css/common.css';
import Layout from "./layout/Layout";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>


  );
}

export default App;