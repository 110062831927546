import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import successImg from '../../assets/images/success.png';
export default function Verification() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const userId = urlParams.get('userId');
    const [failed, setFailed] = useState(false)
    const [success, setSuccess] = useState(false);
    const [resMsg, setResMsg] = useState("")
    const data = {
        userId: userId,
        token: token
    }
    async function verify() {
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + "/Authenticate/ConfirmEmail", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            if (response.status == 200) {
                localStorage.setItem('token', result.token)
                localStorage.setItem('username', result.userName);
                localStorage.setItem('role', result.role);
                localStorage.setItem('email', result.email);
                localStorage.setItem('phoneNumber', result.phoneNumber);
                localStorage.setItem('chargeCurrency', result.chargeCurrency);
                setSuccess(true)
                setResMsg(result.message);
            }
            else throw result
        } catch (err) {
            setFailed(true)
            setResMsg(err.message);

        }
    }
    useEffect(() => {
        verify()
    }, [])
    return failed ? <Failed err={resMsg} /> : <Success success={success} />


}

function Failed({ err }) {
    const navigate = useNavigate();
    return (
        <div className="overlay">
            <div className="p-c p1-5 txt-a-c" style={{ maxWidth: '416px', height: "fit-content" }}>
                <div className="bg-white p-1 br-r-12 br-r-12">
                    <h1 className="primary fs-20px b-500 m-b-1">{err}</h1>
                    <button className="btn pointer mt-2" style={{ width: '80px', height: '40px' }} onClick={() => navigate('/')}>ok</button>
                </div>
            </div>
        </div>
    )
}
function Success({ success }) {
    const isMob = useMediaQuery({ query: '(max-width:1024px)' })
    const navigate = useNavigate();
    return (
        <div className="overlay remove-overlay">
            <div className="p-c p-1-5" style={{ maxWidth: '500px', height: "fit-content" }}>
                <div className="bg-white p-1 br-r-12 show-boxshadow">
                    <div className='txt-a-c'>
                        <img src={successImg} alt="" width="80px" />
                    </div>
                    {
                        success ?
                            <>
                                <h1 className="txt-a-c primary fs-20px b-500 mtb-1">Email Verification Successful</h1>
                                {isMob ? (<div className='d-f f-col f-a-c'>
                                    <button className="br-btn pointer fs-12px" style={{ width: '170px', height: '40px' }} onClick={() => window.location.assign('/home')}>Continue with browser</button>
                                    <span>Or</span>
                                    <button className="btn pointer fs-12px" style={{ width: '170px', height: '40px' }} onClick={() => window.location.assign('/')}>Open with app</button>
                                </div>) :
                                    (
                                        <div className='txt-a-c'>
                                            <button className="btn pointer" style={{ width: '80px', height: '40px' }} onClick={() => window.location.assign('/home')}>ok</button>

                                        </div>
                                    )
                                }
                            </>
                            : <>
                                <h1 className="primary fs-20px b-500 mtb-1 txt-a-c">Verifying</h1>
                                <div className="loader" style={{ borderColor: "var(--primaryColor)" }}></div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}