import { useState } from "react"
import Loader from "../icons/Loader";
import { OpenEye, CloseEye } from "../icons/eye";
import successImg from '../../assets/images/success.png';
import failImg from '../../assets/images/fail.png';
import Err from "./Err";
import { useNavigate } from "react-router-dom";
export default function ChangePassword() {
    const navigate = useNavigate
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    const [submit, setSubmit] = useState(false);
    const [show, setShow] = useState({
        oldPsd: true,
        newPsd: true,
        conPsd: true
    });
    const [err, setErr] = useState({
        oldPassword: '',
        newPassword: '',
        conPassword: ''
    });
    const [resMsg, setResMsg] = useState({
        err: "",
        msg: ""
    });
    const [sucMsg, setSucMsg] = useState(false);
    const [fields, setField] = useState({
        oldPassword: '',
        newPassword: '',
        conPassword: ''
    })
    const [validation, setValidation] = useState({
        oldPassword: false,
        newPassword: false,
        conPassword: false
    })
    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        let errName = name;
        if (name == 'oldPassword') errName = "Old Password";
        else if (name == 'newPassword') errName = 'New password';
        else if (name == 'conPassword') errName = 'Confirm Password';
        let validateEmpty = value.length == 0 ? `${errName} field is empty` : false;
        let validate = '';
        switch (e.target.name) {
            case 'oldPassword':
                if (!validateEmpty) {
                    validate = value.length < 8 ? 'Password must be at least 8 characters' : false;
                    if (!validate) {
                        validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : 'include symbol, numbers and Capital characters'
                        if (!validate) {
                            setValidation({ ...validation, [name]: false })
                            setErr({ ...err, oldPassword: "" })
                            setValidation({
                                ...validation,
                                oldPassword: true
                            })
                        } else setErr({ ...err, oldPassword: validate })
                    } else setErr({ ...err, oldPassword: validate })
                } else setErr({ ...err, [name]: validateEmpty })
                break;
            case 'newPassword':
                if (!validateEmpty) {
                    validate = value.length < 8 ? 'Password must be at least 8 characters' : false;
                    if (!validate) {
                        validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : 'include symbol, numbers and Capital characters'
                        if (!validate) {
                            validate = fields.conPassword !== value ? 'Password not match' : false;
                            setErr({ ...err, conPassword: validate, newPassword: "" })
                            if (!validate) setValidation({ ...validation, newPassword: true, conPassword: true })
                            else setValidation({ ...validation, newPassword: false, conPassword: false })
                        } else {
                            setErr({ ...err, newPassword: validate })
                            setValidation({
                                ...validation,
                                newPassword: false,
                                conPassword: false
                            })
                        }
                    } else setErr({ ...err, newPassword: validate })
                } else setErr({ ...err, [name]: validateEmpty, conPassword: "" })
                break;
            case 'conPassword':
                if (!validateEmpty) {
                    validate = value.length < 8 ? 'Password must be at least 8 characters' : false;
                    if (!validate) {
                        validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : 'missing symbol, numbers and Capital characters'
                        if (!validate) {
                            validate = fields.newPassword !== value ? 'password not match' : false;
                            if (!validate) {
                                setErr({ ...err, [name]: "" })
                                setValidation({
                                    ...validation,
                                    newPassword: true,
                                    conPassword: true
                                })
                            }
                            else {
                                setValidation({
                                    ...validation,
                                    newPassword: false,
                                    conPassword: false
                                })
                                setErr({ ...err, [name]: validate })
                            }
                        }
                    }
                } else setErr({ ...err, [name]: validateEmpty })

                break;
        }
        if (validateEmpty) {
            setValidation({ ...validation, [name]: false })
        }
        setField({ ...fields, [e.target.name]: e.target.value })
    }
    // function validation() {
    //     const checkRegEx = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(fields.newPassword)
    //     if (fields.newPassword.length < 8) {
    //         return "password should be minimum 8 characters ";
    //     } else if (!checkRegEx) {
    //         return "missing symbol, numbers and Capital characters";
    //     }
    //     else if (fields.newPassword != fields.conPassword) {
    //         return "password not match";
    //     } else {
    //         return false;
    //     }
    // }
    function handleSubmit(e) {
        e.preventDefault()
        postNewPsd();
    }
    async function postNewPsd() {
        setSubmit(true);
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/Authenticate/changePassword', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(
                    {
                        oldPassword: fields.oldPassword,
                        newPassword: fields.newPassword
                    }
                )
            });

            const result = await response.json();
            if (response.status == 200) {
                // window.location.assign('/');
                setSucMsg({
                    suc: true,
                })
            }
            else {
                throw result
            }
        } catch (err) {
            setSubmit(false);
            setResMsg({
                err: true,
                message: err.message,
                alertMessage: err.alertMessage
            })
        }
        // finally {
        //     setTimeout(() => {
        //         localStorage.clear();
        //         window.location.assign('/');
        //     }, 10000)
        // }
    }
    const style = {
        input: {
            background: 'var(--inputGray)',
            color: 'black',
            border: 'none',
            maxWidth: '352px',
            height: '48px',
            borderRadius: '8px'
        }
    }
    return (
        <div className="d-f f-j-c f-a-c" style={{ height: 'calc(100vh - 98px)' }}>
            <div className="p-2 sm-p-1 br-r-12 sm-shadow-n" style={{ maxWidth: '416px', height: 'fit-content', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="sm-p-1 bg-white br-r-12">
                    <h1 className="fs-20px b-500 primary mb-1 sm-txt-c">Set your new password</h1>
                    <p className="gray mb-1-5 sm-txt-c">Reinforce your account's security <br />
                        by set a new password.</p>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="" className="b-500">Old Password</label> <br />
                            <div style={{ position: 'relative' }} className="mt-8px">
                                <input onChange={handleChange} className="pl-1-5 w-100" type={show.oldPsd ? 'password' : 'text'} style={style.input} name="oldPassword" placeholder="Old Password" required />
                                <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, oldPsd: !(show.oldPsd) })} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, right: '1rem' }}>
                                    {show.oldPsd ? <CloseEye /> : <OpenEye />}
                                </span>
                            </div>
                            {err.oldPassword && <span className="red">{err.oldPassword}</span>}
                        </div>
                        <div className="mt-1-5">
                            <label htmlFor="" className="b-500">New Password</label> <br />
                            <div style={{ position: 'relative' }} className="mt-8px">
                                <input onChange={handleChange} className="pl-1-5 w-100" type={show.newPsd ? 'password' : 'text'} style={style.input} name="newPassword" placeholder="New Password" required />
                                <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, newPsd: !(show.newPsd) })} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, right: '1rem' }}>
                                    {show.newPsd ? <CloseEye /> : <OpenEye />}
                                </span>
                            </div>
                            {err.newPassword && <span className="red">{err.newPassword}</span>}
                        </div>
                        <div className="mt-1-5">
                            <label htmlFor="" className="b-500">Confirm Password</label> <br />
                            <div style={{ position: 'relative' }} className="mt-8px">
                                <input onChange={handleChange} className="pl-1-5 w-100" type={show.conPsd ? 'password' : 'text'} style={style.input} name="conPassword" placeholder="Confirm Password" required />
                                <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, conPsd: !(show.conPsd) })} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, right: '1rem' }}>
                                    {show.conPsd ? <CloseEye /> : <OpenEye />}
                                </span>
                            </div>
                            {err.conPassword && <span className="red">{err.conPassword}</span>}
                        </div>
                        <div className="d-f c-gap-1 f-wrap r-gap-1 f-j-c mt-2 ">
                            <button className="p-r btn pointer" disabled={!(validation.oldPassword && validation.newPassword && validation.conPassword)} style={{ opacity: (validation.oldPassword && validation.newPassword && validation.conPassword) ? 1 : '.4', width: "171px", color: submit ? '#ffffff54' : 'white' }} >Reset Password {submit && <Loader color="black" />}</button>
                            <button className="br-btn pointer" style={{ width: "171px" }} onClick={() => navigate('/')}>Cancel</button>
                        </div>
                    </form>
                    {resMsg.err && <div className="overlay"><Err onClick={() => setResMsg({ ...resMsg, err: "" })} data={resMsg} click={() => setResMsg("")} /></div>}
                    {sucMsg && <Success msg={sucMsg.msg} />}
                </div>
            </div>
        </div>
    )
}

function Success({ msg }) {
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ maxWidth: '450px', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={successImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c'>
                        <p className='primary'>Password has been changed Successfully</p>
                        <p>please login with new password</p>
                        <button className='pointer msg-btn mt-1' onClick={() => { localStorage.clear(); window.location.assign('/'); }}>ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

// function Err({ err, onClick }) {
//     console.log(err)
//     return (
//         <div className="p-c p-1" style={{ maxWidth: '365px', height: 'fit-content' }}>
//             <div className="p-1 br-r-12 bg-white">
//                 {/* <h1 className="red fs-20px b-500">Error . . .</h1> */}
//                 <div className='txt-a-c'><img src={failImg} alt="" width="50px" /></div>
//                 <p className="txt-a-c">{err.message}</p>
//                 <div className="txt-a-c mt-1">
//                     <button className="btn pointer" style={{ width: '70px', height: '34px' }} onClick={onClick}>ok</button>
//                 </div>
//             </div>
//         </div>
//     )
// }