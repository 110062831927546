import { useMediaQuery } from 'react-responsive';
import VoyagesProcess from "./desk/VoyagesProcess";
import MobVoyagesProcess from "./mobile/VoyagesProcess";
import TabVoyagesProcess from "./tab/VoyagesProcess";
// import FareSummary from '../components/common/FareSummary';
import { Outlet, useLocation } from 'react-router-dom';
import { ChangeDatecontext } from '../ProcessContext';
import { useRef, useState } from 'react';
export default function ChangeDateProcess({ date, setDate, setOpenSearch }) {
    const [travelDate, setTravelDate] = useState('')
    const [returnTravelDate, setReturnTravelDate] = useState('')
    const [showSearchBtn, setShowSearchBtn] = useState(true);
    const [process, setProcess] = useState({
        processSecond: "",
        processThird: ""
    })

    const isMob = useMediaQuery({ query: '(max-width:999px)' })
    const isTab = useMediaQuery({ minWidth: 1000, maxWidth: 1279 })
    const isDesk = useMediaQuery({ query: '(min-width: 1280px)' })
    return (
        <div>
            <ChangeDatecontext.Provider value={{
                setProcess,
                process,
                travelDate,
                setTravelDate,
                returnTravelDate,
                setReturnTravelDate,
                date,
                setDate,
                setShowSearchBtn,
                showSearchBtn
            }}>
                {isMob && <MobVoyagesProcess setOpenSearch={setOpenSearch} />}
                {isTab && <TabVoyagesProcess setOpenSearch={setOpenSearch} />}
                {isDesk && <VoyagesProcess setOpenSearch={setOpenSearch} />}
                {/* <VoyageList /> */}
                {/* <FareSummary /> */}
                <Outlet />
            </ChangeDatecontext.Provider>
        </div>
    )
}