import { useState } from "react";
import DateTimeCard from "../common/DateTimeCard";
import { BookBtn } from "../btn";
import ViewDetails from "../common/ViewDetails";
export default function YourVoyages({ book, maxLuggage }) {
    const [view, setView] = useState(false)
    const style = {
        container: {
            width: '920px',
            padding: "1.5rem",
            borderRadius: '16px',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'
        },
        codeBatch: {
            width: "108px",
            height: "64px",
            backgroundColor: "#D9D9D9",
            borderRadius: '14px',
            display: 'flex',
            alignItems: "center",
            justifyContent: "center"
        },
        amountBatch: {
            width: "112px",
            height: "112px",
            backgroundColor: "#D9D9D9",
            borderRadius: '14px',
            display: 'flex',
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center"
        }
    }
    return (
        <div className="" style={style.container}>
            <div className="d-f f-spb">
                <div className="d-f f-col f-spb">
                    <div style={style.codeBatch} className="b-600">{book.voyageId}</div>
                    <span style={{ color: "black", textDecoration: 'underline', cursor: "pointer" }} onClick={() => setView(!view)}>{view ? 'Close details' : 'View details'}</span>
                </div>
                <DateTimeCard fs={{ date: 'fs-14px', time: 'fs-1-5' }} data={book} />
                <div className="d-f f-a-c">
                    <span>Class:</span><span className="b-700">&nbsp;{book.className}</span>
                </div>
                <div style={style.amountBatch}>
                    <span>{book.chargeCurrency}</span>
                    <span className="fs-1-5 bold-500">{book.totalChargeAmount.toLocaleString()}</span>
                </div>
            </div>
            {view && <ViewDetails maxLuggage={maxLuggage} />}
        </div>
    )
}