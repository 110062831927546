export default function Payment({ proceedPayment }) {
    return (
        <div id="payment" className="p-2 br-r-12 p-c bg-white" style={{ width: 'fit-content', height: 'fit-content' }}>
            <h1 className="sm-txt-c primary sm-fs-1 fs-20px b-500">Provide your payment details</h1>
            <h2 className="sm-txt-c sm-fs-14px gray">Pay & Book your ticket(s)</h2>
            <div className="d-f f-wrap mt-1-5 mb-2 r-gap-1 f-spb">
                <div className="wrapper">
                    <label className="sm-fs-12px  b-500" htmlFor="">Name on Debit / Credit Card</label><br />
                    <input className="sm-fs-12px" type="text" placeholder="Name on Debit Credit Card" />
                </div>
                <div className="wrapper">
                    <label className="sm-fs-12px b-500" htmlFor="">Date of Expiry</label><br />
                    <input className="sm-fs-12px" type="text" placeholder="Date of Expiry" />
                </div>
                <div className="wrapper">
                    <label className="sm-fs-12px b-500" htmlFor="">Debit / Credit Card Number</label><br />
                    <input className="sm-fs-12px" type="text" placeholder="Debit Credit Card Number" />
                </div>
                <div className="wrapper">
                    <label className="sm-fs-12px b-500" htmlFor="">Security Code</label><br />
                    <input className="sm-fs-12px" type="text" placeholder="Security Code" />
                </div>
            </div>
            <div className="txt-a-c">
                <button className="btn pointer" onClick={proceedPayment}>Make Payment</button>
            </div>
        </div>
    )
}