import { useContext, useEffect, useRef } from 'react';
import { ChangeDatecontext } from '../../ProcessContext';
export default function VoyagesProcess({ setOpenSearch }) {
    const processSecondRef = useRef();
    const processThirdRef = useRef();
    const {
        setProcess,
        date,
        showSearchBtn,
    } = useContext(ChangeDatecontext);
    useEffect(() => {
        setProcess({
            processSecond: processSecondRef.current,
            processThird: processThirdRef.current
        })
    }, []);
    return (
        <div className="p1-5 box-size d-f f-col f-spb" style={{ background: "#D9D9D9", height: '249px' }}>
            <div className="d-f f-spb">
                <div className="d-f a-i-c c-gap-1 rem">
                    <div className=" fs-20px b-500 d-f c-cont process-round white primary-bg">1</div>
                    <h1 className="fs-20px b-500">Plan Voyage</h1>
                </div>
                {/* <div className="d-f a-i-c c-gap-1 rem">
                    <div ref={processSecondRef} className="fs-20px b-500 d-f c-cont process-round primary">2</div>
                    <h1 className="fs-20px b-500">Change & Book</h1>
                </div> */}
                <div className="d-f a-i-c c-gap-1 rem">
                    <div ref={processThirdRef} className="fs-20px b-500 d-f c-cont process-round primary">3</div>
                    <h1 className="fs-20px b-500">Change & Book</h1>
                </div>
            </div>
            <div className="d-f f-spb">
                <div>
                    <div className="d-f">
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.dateOfTravel}</span>
                            <span className="fs-1-5 b-500">{date.voyageFrom}</span>
                        </div>
                        <div>....Departure....</div>
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.dateOfTravel}</span>
                            <span className="fs-1-5 b-500">{date.voyageTo}</span>
                        </div>
                    </div>
                </div>
                <div className="d-f">
                    <div className="d-f f-col">
                        <span className="fs-14px b-500">{date.returnDateOfTravel}</span>
                        <span className="fs-1-5 b-500">{date.returnVoyageFrom}</span>
                    </div>
                    <div>....Return....</div>
                    <div className="d-f f-col">
                        <span className="fs-14px b-500">{date.returnDateOfTravel}</span>
                        <span className="fs-1-5 b-500">{date.returnVoyageTo}</span>
                    </div>
                </div>
            </div>
            <div className="txt-a-c">
                <button className='br-btn primary pointer' style={{ visibility: `${showSearchBtn ? 'visible' : 'hidden'}` }} onClick={() => setOpenSearch(true)}>Modify Date</button>
            </div>
        </div>
    )
}