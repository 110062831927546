import { useState } from "react";
import DateTimeCard from "../common/DateTimeCard";
import { BookBtn } from "../btn";
import ViewDetails from "../common/ViewDetails";
export default function VoyageCard({ luggageInfo, data, book, isRound, setBook, type, setShowReturn }) {
    const [view, setView] = useState(false)
    const style = {
        container: {
            width: '1088px',
            padding: "1.5rem",
            borderRadius: '16px',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'
        },
        codeBatch: {
            width: "108px",
            height: "64px",
            backgroundColor: "#D9D9D9",
            borderRadius: '14px',
            display: 'flex',
            alignItems: "center",
            justifyContent: "center"
        },

        amountBatch: {
            width: "112px",
            height: "112px",
            backgroundColor: "#D9D9D9",
            borderRadius: '14px',
            display: 'flex',
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        seatBatch: {
            width: 'fit-content',
            backgroundColor: "#ec9e39",
            position: 'absolute',
            top: 0,
            right: '3rem',
            padding: '0 .5rem'
        }
    }
    return (
        <div style={style.container} className="voyage-card p-r fade">
            <div className="d-f f-spb">
                <div className="d-f f-col f-spb">
                    <div style={style.codeBatch} className="b-600">{data.voyageId}</div>
                    <span style={{ color: "var(--primaryColor)", textDecoration: 'underline', cursor: "pointer" }} onClick={() => setView(!view)}>{view ? 'Close details' : 'View details'}</span>
                </div>
                <DateTimeCard fs={{ date: 'fs-14px', time: 'fs-1-5' }} data={data} />
                <div className="d-f f-a-c">
                    <span>Class:</span><span className="b-700">&nbsp;{data.className}</span>
                </div>
                <div style={style.amountBatch}>
                    <span>{data.chargeCurrency}</span>
                    <span className="fs-1-5 b-600">{data.totalChargeAmount.toLocaleString()}</span>
                </div>
                <div className="d-f f-a-c">
                    <span className="fs-14px b-500" style={style.seatBatch}>{data.availableSeat <= 10 ? data.availableSeat + " Seats Left" : ""}</span>
                    <BookBtn luggageInfo={luggageInfo} isRound={isRound} book={book} setBook={setBook} type={type} id={data.voyageId} data={data} setShowReturn={setShowReturn}>Book</BookBtn>
                </div>
            </div>
            {view && <ViewDetails maxLuggage={data.maxLuggage} />}
        </div>
    )
}
