import failImg from '../assets/images/fail.png';
export default function ServerErr() {
    return (
        <div className="overlay" style={{ height: 'calc(-80px + 100vh)', top: "80px" }}>
            <div className="p-c p1-5 txt-a-c" style={{ maxWidth: '416px', height: "fit-content" }}>
                <div className="bg-white p-1 br-r-12 br-r-12">
                    <div className='txt-a-c'><img src={failImg} alt="" width="50px" /></div>
                    <h1 className="primary fs-20px b-500 m-b-1">Internal Server Error</h1>
                    <p className="gray">Please try again in a few minutes.</p>
                </div>
            </div>
        </div>
    )
}