import { useParams, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import { useEffect, useState } from 'react';
import { getPaxDetail } from '../../api/api';
import Loader from '../icons/Loader';
import { useMediaQuery } from 'react-responsive';
import Ticket from './Ticket';
const html2pdf = require('html2pdf.js');
export default function EmailTicket() {
    const url = useLocation();
    const [loader, setLoader] = useState(true);
    const params = useParams()
    const [data, setData] = useState({
        bookingId: '',
        passengerCount: '',
        completedPaxes: [],
        viewDetails: {
            chargeCurrency: "USD"
        },
        luggageInfo: {
            luggageOneWay: 0,
            luggageReturnWay: 0
        },
        seatInfo: {
            seatListOneWay: [],
            seatListReturnWay: []
        },
        menuInfo: {
            menuOneWay: [],
            menuReturnWay: []
        },
        fareDetails: {
            baseFare: 0,
            optionalServices: 0,
            taxes: 0,
            totalAmount: 0
        },
        fareBreakDown: {
            portLevy: [],
            travel: {
                baseFare: 0,
                taxes: []
            },
            food: {
                baseFare: 0,
                taxes: []
            },
            luggage: {
                baseFare: 0,
                taxes: []
            }
        },
        userName: '',
        phoneNumber: '',
        email: ''
    })
    const paxType = {
        1: "ADT",
        2: "CHLD",
        3: "INFT"
    }
    var opt = {
        margin: 0,
        filename: data.bookingId ? data.bookingId.join('-') + '.pdf' : 'ticket.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    useEffect(() => {
        if (document.getElementById('backBtnContainer')) document.getElementById('backBtnContainer').style.display = 'none';
        if (document.getElementById('header')) document.getElementById('header').style.display = 'none';
        if (document.getElementById('main')) document.getElementById('main').style.marginTop = 0;
        getPaxDetail({ bookingId: [params.cCode, params.dCode, params.year, params.SNo], isEmail: true }).then(data => {
            if (data) {
                const paxCount = []
                if (data.passengerCount.adultCount) paxCount.push(data.passengerCount.adultCount + ' ADT')
                if (data.passengerCount.childCount) paxCount.push(data.passengerCount.childCount + ' CHLD')
                if (data.passengerCount.infantCount) paxCount.push(data.passengerCount.infantCount + ' INFT')
                setData({
                    bookingId: data.bookingId,
                    passengerCount: paxCount,
                    completedPaxes: data.bookingPax,
                    viewDetails: data.viewDetails,
                    seatInfo: data.seatInfo,
                    luggageInfo: data.luggageInfo,
                    seatInfo: data.seatInfo,
                    menuInfo: data.menuInfo,
                    fareDetails: data.fareDetails,
                    fareBreakDown: data.fareBreakDown,
                    dateOfIssue: data.dateOfIssue,
                    userName: data.userName,
                    phoneNumber: data.phoneNumber,
                    email: data.email
                })
                // opt.filename = params.cCode + '-' + params.dCode + '-' + params.year + '-' + params.SNo + '.pdf'
                // var element = document.querySelector("#elementId");
                // html2pdf(element, opt)
                setLoader(false);
            }
        })




    }, [])
    return (
        loader ?
            <div className="overlay">
                <Loader color="white" />
            </div>
            : <>
                <div className='txt-a-c'><button className='primary txt-a-c txt-dec-u b-500 pointer btn' onClick={() => {
                    var element = document.querySelector("#elementId");
                    html2pdf(element, opt)
                }}>Click to Download</button></div>
                <Ticket data={data} />
            </>)
    // return (<><h1 className='primary txt-a-c txt-dec-u b-500 pointer' onClick={() => {
    //     var element = document.querySelector("#elementId");
    //     html2pdf(element, opt)
    // }}>Click to Download</h1>
    //     <div style={{ width: "600px", margin: "1rem auto" }} id='elementId'>
    //         <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
    //             <img src={logo} width="70px" />
    //         </div>
    //         <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 1rem" }}>
    //             <div>
    //                 <p style={{ display: "flex" }}>
    //                     <span style={{ fontSize: "12px" }}>BOOKING REFERENCE:&nbsp;</span>
    //                     <span style={{ fontSize: "12px", fontWeight: 700 }}>{data.bookingId ? data.bookingId.join('-') : ""}</span>
    //                 </p>
    //                 <p style={{ display: "flex" }}>
    //                     <span style={{ fontSize: "12px" }}>PASSENGERS:&nbsp;</span>
    //                     <span style={{ fontSize: "12px", fontWeight: 700 }}>{data.passengerCount ? data.passengerCount.join(',') : ""}</span>
    //                 </p>
    //                 <p style={{ display: "flex" }}>
    //                     <span style={{ fontSize: "12px" }}>DATE OF ISSUE:&nbsp;</span>
    //                     <span style={{ fontSize: "12px", fontWeight: 700 }}>{data.dateOfIssue}</span>
    //                 </p>
    //                 <p style={{ display: "flex" }}>
    //                     <span style={{ fontSize: "12px" }}>BOOKED BY:&nbsp;</span>
    //                     {/* <span style={{ fontSize: "12px", fontWeight: 700 }}>{localStorage.getItem('username').toUpperCase()}</span> */}
    //                 </p>
    //                 <p style={{ display: "flex", fontSize: "12px", fontWeight: 600 }}>
    //                     {localStorage.getItem('phoneNumber')},&nbsp;
    //                     {localStorage.getItem('email')}
    //                 </p>
    //             </div>
    //             <div>
    //                 <p style={{ fontSize: "12px" }}>ISSUING OFFICE:</p>
    //                 <p style={{ fontSize: "12px", fontWeight: 700 }}>INDSRI FERRY SERVICES (PVT)LTD</p>
    //                 <p style={{ fontSize: "12px", fontWeight: 700 }}>WWW.SAILINDSRI.COM, ONLINE BOOKING</p>
    //             </div>
    //         </div>
    //         <h1 style={{ fontSize: '1rem', fontWeight: 700, padding: "8px 24px", marginTop: "1.5rem", backgroundColor: "#1F3167", color: "white" }}>ELECTRONIC TICKET RECEIPT</h1>
    //         <div style={{ padding: "1rem" }}>
    //             <div>
    //                 <p style={{ fontSize: "8px" }}>Your electronic ferry ticket is securely stored in our reservations system. It is mandatory to carry this document along with any other travel-related documents specified by local and international security, customs, and passport control authorities. The ferry service will not assume responsibility for any  consequences arising from your failure to possess the necessary travel documents.</p>
    //                 <p style={{ fontSize: "8px", marginTop: "1rem" }}>Our check-in counters open 3 hours before the scheduled ferry departure and close 1 hour before departure globally, unless otherwise specified. You must check in during this timeframe to avoid any denial of boarding.</p>
    //             </div>
    //             <div style={{ marginTop: '1rem' }}>
    //                 <div style={{ padding: "8px 1rem", backgroundColor: "#1F3167", display: "flex", justifyContent: "space-between" }}>
    //                     <p style={{ color: 'white', fontSize: "12px", fontWeight: 600 }}>From</p>
    //                     <p style={{ color: 'white', fontSize: "12px", fontWeight: 600 }}>To</p>
    //                     <p style={{ color: 'white', fontSize: "12px", fontWeight: 600 }}>Ferry</p>
    //                     <p style={{ color: 'white', fontSize: "12px", fontWeight: 600 }}>Departure</p>
    //                     <p style={{ color: 'white', fontSize: "12px", fontWeight: 600 }}>Arrival</p>
    //                 </div>
    //                 <div style={{ background: "#D9D9D9", padding: "8px 1rem", display: "flex", justifyContent: "space-between" }}>
    //                     <p style={{ fontSize: "12px", fontWeight: 600 }}>{data.viewDetails ? data.viewDetails.voyageFromName : ''}</p>
    //                     <p style={{ fontSize: "12px", fontWeight: 600 }}>{data.viewDetails ? data.viewDetails.voyageToName : ''}</p>
    //                     <p style={{ fontSize: "12px", fontWeight: 600 }} >{data.viewDetails ? data.viewDetails.ferryId : ''}</p>
    //                     <p style={{ fontSize: "12px", fontWeight: 600, display: "flex", flexFlow: "column" }}>{data.viewDetails ? data.viewDetails.voyageFromTime : ''} <br /><span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravel : ''}</span></p>
    //                     <p style={{ fontSize: "12px", fontWeight: 600, display: "flex", flexFlow: "column" }}>{data.viewDetails ? data.viewDetails.voyageToTime : ''} <br /><span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravel : ''}</span></p>
    //                 </div>
    //                 <div style={{ columnGap: "100px", display: "flex", marginTop: "8px", paddingLeft: '1rem' }}>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Class:&nbsp;</span><span style={{ fontSize: '8px' }}>{data.viewDetails.className}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Baggage:&nbsp;</span><span style={{ fontSize: '8px' }}>{data.luggageInfo.luggageOneWay} kg</span></p>
    //                     </div>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Operated by:&nbsp;</span><span style={{ fontSize: '8px' }}>INDSRI</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Marketed by:&nbsp;</span><span style={{ fontSize: '8px' }}> INDSRI</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Booking status (1):&nbsp;</span><span style={{ fontSize: '8px' }}>Ok</span></p>
    //                     </div>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>NVB (2):&nbsp;</span><span style={{ fontSize: '8px' }}>{data.viewDetails.dateOfTravel}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>NVA (3):&nbsp;</span><span style={{ fontSize: '8px' }}>{data.viewDetails.dateOfTravel}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: '8px', fontWeight: 700 }}>Voyage duration:&nbsp;</span><span style={{ fontSize: '8px' }}>{data.viewDetails.duration}</span></p>
    //                     </div>
    //                 </div>
    //             </div>
    //             {data.viewDetails.type == 2 && <div>
    //                 <div style={{ padding: "8px 1rem", backgroundColor: "#1F3167", display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
    //                     <p style={{ color: 'white', fontSize: '12px', fontWeight: 600 }}>From</p>
    //                     <p style={{ color: 'white', fontSize: '12px', fontWeight: 600 }}>To</p>
    //                     <p style={{ color: 'white', fontSize: '12px', fontWeight: 600 }}>Ferry</p>
    //                     <p style={{ color: 'white', fontSize: '12px', fontWeight: 600 }}>Departure</p>
    //                     <p style={{ color: 'white', fontSize: '12px', fontWeight: 600 }}>Arrival</p>
    //                 </div>
    //                 <div style={{ background: "#D9D9D9", padding: "8px 1rem", display: "flex", justifyContent: 'space-between' }}>
    //                     <p style={{ fontWeight: 600, fontSize: "12px" }}>{data.viewDetails ? data.viewDetails.voyageFromReturnName : ''}</p>
    //                     <p style={{ fontWeight: 600, fontSize: "12px" }}>{data.viewDetails ? data.viewDetails.voyageToReturnName : ''}</p>
    //                     <p style={{ fontWeight: 600, fontSize: "12px" }} >{data.viewDetails ? data.viewDetails.ferryIdReturn : ''}</p>
    //                     <p style={{ fontWeight: 600, fontSize: "12px", display: "flex", flexFlow: "column" }}>{data.viewDetails ? data.viewDetails.voyageFromTimeReturn : ''} <br /><span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravelReturn : ''}</span></p>
    //                     <p style={{ fontWeight: 600, fontSize: "12px", display: "flex", flexFlow: "column" }}>{data.viewDetails ? data.viewDetails.voyageToTimeReturn : ''} <br /><span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravelReturn : ''}</span></p>
    //                 </div>
    //                 <div style={{ columnGap: "100px", display: "flex", marginTop: "8px", paddingLeft: "1rem" }}>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Class:&nbsp;</span><span style={{ fontSize: "8px" }}>{data.viewDetails.className}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Baggage:&nbsp;</span><span style={{ fontSize: "8px" }}>{data.luggageInfo.luggageReturnWay} kg</span></p>
    //                     </div>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Operated by:&nbsp;</span><span style={{ fontSize: "8px" }}>INDSRI</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Marketed by:&nbsp;</span><span style={{ fontSize: "8px" }}> INDSRI</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Booking status (1):&nbsp;</span><span style={{ fontSize: "8px" }}>Ok</span></p>
    //                     </div>
    //                     <div>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>NVB (2):&nbsp;</span><span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravelReturn}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>NVA (3):&nbsp;</span><span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravelReturn}</span></p>
    //                         <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Voyage duration:&nbsp;</span><span style={{ fontSize: "8px" }}>{data.viewDetails.durationReturn}</span></p>
    //                     </div>
    //                 </div>
    //             </div>}
    //             <hr style={{ marginTop: "8px" }} />
    //             <div style={{ display: "flex", columnGap: '1rem', display: "flex", columnGap: "1rem" }}>
    //                 <p><span style={{ fontSize: "8px", fontWeight: 500 }}>(1) Ok = </span><span style={{ fontSize: "8px", fontWeight: 700 }}>Confirmed</span></p>
    //                 <p><span style={{ fontSize: "8px", fontWeight: 500 }}>(2) NVB = </span><span style={{ fontSize: "8px", fontWeight: 700 }}>Not valid before</span></p>
    //                 <p><span style={{ fontSize: "8px", fontWeight: 500 }}>(3) NVA = </span><span style={{ fontSize: "8px", fontWeight: 700 }}>Not valid after</span></p>
    //             </div>
    //             <hr />
    //             <h1 style={{ fontSize: '1rem', fontWeight: 700, marginTop: "1.5rem", padding: "8px 24px", backgroundColor: "#1F3167", color: "white" }}>Passenger Information</h1>
    //             <div style={{ justifyContent: "space-between", rowGap: '1rem', display: "grid", gridTemplateColumns: "auto auto auto", background: "#D9D9D9", padding: "8px 1rem" }}>
    //                 {data.completedPaxes.length > 0 && data.completedPaxes.map((item, index) =>
    //                     <div key={index}>
    //                         <p style={{ fontSize: "12px", fontWeight: 600 }}>{paxType[item.passengerType]} {item.seqNo}</p>
    //                         <p style={{ fontSize: "10px", fontWeight: 600 }}>Full name:</p>
    //                         <p style={{ fontSize: "10px" }}>{item.otherName} {item.sureName}</p>
    //                     </div>
    //                 )}
    //             </div>
    //             <h1 style={{ fontSize: '1rem', fontWeight: 700, marginTop: "1.5rem", padding: "8px 24px", backgroundColor: "#1F3167", color: "white" }}>Optional Services</h1>
    //             <div style={{ background: "#D9D9D9", padding: "8px 1rem" }}>
    //                 <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                     <div>
    //                         <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Preferred Seat</h1>
    //                         <div>
    //                             <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}:</p>
    //                             <div style={{ fontSize: "10px", fontWeight: 700 }}>
    //                                 {data.seatInfo.seatListOneWay.length > 0 && data.seatInfo.seatListOneWay.join(',')}
    //                             </div>
    //                         </div>
    //                         {data.viewDetails.type == 2 && <div style={{ marginTop: "8px" }}>
    //                             <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}:</p>
    //                             <div style={{ fontSize: "10px", fontWeight: 700 }}>
    //                                 {data.seatInfo.seatListReturnWay.length > 0 && data.seatInfo.seatListReturnWay.join(',')}
    //                             </div>
    //                         </div>}
    //                     </div>
    //                     <div>
    //                         <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Extra Luggage</h1>
    //                         <div>
    //                             <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}: <span style={{ fontSize: "10px" }}>{data.luggageInfo.luggageOneWay} KG</span> </p>
    //                             {
    //                                 data.viewDetails.type == 2 && <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}: <span style={{ fontSize: "10px" }}>{data.luggageInfo.luggageReturnWay} KG</span> </p>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div>
    //                         <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Preferred Meal</h1>
    //                         <div>
    //                             <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}:</p>
    //                             <div style={{ fontSize: "10px", fontWeight: 700 }}>
    //                                 {data.menuInfo.menuOneWay.length > 0 && data.menuInfo.menuOneWay.map((item, index) =>
    //                                     <p key={index} style={{ fontSize: "10px", fontWeight: 700 }}>{item.menuName} x {item.quantity}</p>
    //                                 )}
    //                             </div>
    //                         </div>
    //                         {data.viewDetails.type == 2 && <div style={{ marginTop: '8px' }}>
    //                             <p style={{ fontSize: "10px", fontWeight: 600 }}>{data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}:</p>
    //                             <div style={{ fontSize: "10px", fontWeight: 700 }}>
    //                                 {data.menuInfo.menuReturnWay.length > 0 && data.menuInfo.menuReturnWay.map((item, index) =>
    //                                     <p key={index} style={{ fontSize: "10px", fontWeight: 700 }}>{item.menuName} x {item.quantity}</p>
    //                                 )}
    //                             </div>
    //                         </div>}
    //                     </div>
    //                 </div>
    //             </div>
    //             <h1 style={{ fontSize: '1rem', marginTop: "1.5rem", fontWeight: 700, padding: "8px 24px", backgroundColor: "#1F3167", color: "white" }}>Fair Details</h1>
    //             <div style={{ background: "#D9D9D9", padding: "8px 1rem" }}>
    //                 <p style={{ display: "flex" }}><span style={{ fontSize: "10px", fontWeight: 600 }}>Base Fare:&nbsp;</span><span style={{ fontSize: "10px" }}>{data.fareDetails.baseFare.toLocaleString("en-GB", { style: "currency", currency: data.viewDetails ? data.viewDetails.chargeCurrency : "LKR" })}</span></p>
    //                 <p style={{ display: "flex" }}><span style={{ fontSize: "10px", fontWeight: 600 }}>Taxes:&nbsp;</span><span style={{ fontSize: "10px" }}>{data.fareDetails.taxes.toLocaleString("en-GB", { style: "currency", currency: data.viewDetails ? data.viewDetails.chargeCurrency : "LKR" })}</span></p>
    //                 <p style={{ display: "flex" }}><span style={{ fontSize: "10px", fontWeight: 600 }}>Optional Services:&nbsp;</span><span style={{ fontSize: "10px" }}>{data.fareDetails.optionalServices.toLocaleString("en-GB", { style: "currency", currency: data.viewDetails ? data.viewDetails.chargeCurrency : "LKR" })}</span></p>
    //                 <p><span style={{ fontSize: "10px", fontWeight: 600 }}>Total Amount:&nbsp;</span><span style={{ fontSize: "10px" }}>{data.fareDetails.totalAmount.toLocaleString("en-GB", { style: "currency", currency: data.viewDetails ? data.viewDetails.chargeCurrency : "LKR" })}</span></p>
    //             </div>
    //             <h1 style={{ fontSize: '1rem', bold: 700, marginTop: "1.5rem", padding: "8px 24px", backgroundColor: "#1F3167", color: "white" }}>Passenger Notes</h1>
    //             <div style={{ background: "#D9D9D9", padding: "8px 1rem" }}>
    //                 <p style={{ fontSize: "8px" }}>
    //                     Your electronic ferry ticket is securely stored in our reservations system. It is mandatory to carry this document along with any other travel-related documents specified by local and international security, customs, and passport control authorities. The ferry service will not assume responsibility for any  consequences arising from your failure to possess the necessary travel documents.
    //                 </p>
    //                 <p style={{ fontSize: "8px", marginTop: "1rem" }}>
    //                     Our check-in counters open 3 hours before the scheduled ferry departure and close 1 hour before departure globally, unless otherwise specified. You must check in during this timeframe to avoid any denial of boarding.
    //                 </p>
    //                 <h1 className='fs-8px b-500 mt-1'>Baggage & Refund Policy</h1>
    //                 <ul className='pl-1'>
    //                     <li className='fs-8px'>Passengers who booked confirmed tickets will get full refund if they cancelled 72 hours before the scheduled departure.</li>
    //                     <li className='fs-8px'>Tickets can celled within 72 hours basic fares will NOT be refunded but taxes and levied charged will be refunded in full. </li>
    //                     <li className='fs-8px'>Date change before 72 hours is allowed subject to availability and at a cost of USD 12.50 + Applicable taxes.</li>
    //                     <li className='fs-8px'>Date change before 72 hours is allowed subject to availability and at a cost of USD 25 + Applicable taxes. </li>
    //                     <li className='fs-8px'>Free Baggage allowance is 60 kg for every adult passenger and 30 kg for a child passenger. Infants will not have any free baggage allowance. </li>
    //                     <li className='fs-8px'>Baggage shouldn’t be more than 20 kg each and 3 baggage’s are allowed for each adult passengers. Similar to rules applicable to child passengers.</li>
    //                     <li className='fs-8px'>There will fine for not complying to baggage rules. </li>
    //                     <li className='fs-8px'>Excess Baggage is allowed to a maximum of 15 Kgs for each adult passenger and NO excess is allowed for a child passenger.</li>
    //                     <li className='fs-8px'>
    //                         Excess will be charged at
    //                         <ol className='pl-1'>
    //                             <li className='fs-8px'>
    //                                 Less than 10 kg will be charged at INR 100 + Taxes per Kg
    //                             </li>
    //                             <li className='fs-8px'>
    //                                 Next 5 Kg will be charged at INR 1,200 per Kg + Taxes per Kg
    //                             </li>
    //                             <li className='fs-8px'>Anything excess of 15 Kg will be charged at INR 2,000 + taxes per Kg.</li>
    //                         </ol>
    //                     </li>
    //                 </ul>
    //                 <p style={{ marginTop: "1rem", fontSize: "8px", fontWeight: 700 }}>
    //                     This advisory is generated by the IndSri ferry services computer system and does not require a signature.
    //                     Your ticket record is electronically stored with IndSri
    //                 </p>
    //             </div>
    //         </div>
    //         <div style={{ bold: 700, display: "flex", justifyContent: "center", columnGap: "1rem", alignItems: "center", height: "57px", backgroundColor: "#1F3167", color: "white" }}>
    //             <p style={{ fontSize: "8px", fontWeight: 700 }}>For the further clarifications:</p>
    //             <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>India:&nbsp;</span><span style={{ fontSize: "8px" }}>+91 96776 65874</span></p>
    //             <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>SriLanka:&nbsp;</span><span style={{ fontSize: "8px" }}>+94 11 617 2547</span></p>
    //             <p style={{ display: "flex" }}><span style={{ fontSize: "8px", fontWeight: 700 }}>Email:&nbsp;</span><span style={{ fontSize: "8px" }}>bookings@sailindsri.com</span></p>
    //         </div>
    //     </div >
    // </>
    // )
}