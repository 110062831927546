import img1 from '../assets/images/aboutUs1.jpeg';
import img2 from '../assets/images/aboutUs2.jpeg';
export default function AboutUs() {
    return (
        <div className="br-r-1 bg-white m-a h-100" style={{ overflow: 'auto', maxWidth: "896px", padding: "2rem 1.5rem", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <h1 className="fs-20px b-500">IndSri Ferry  - Connecting Cultures, Bridging Park Street </h1>
            <p className="mt-1-5">Welcome to IndSri Ferry! We're a new passenger ferry service, launching in Summer 2024, that's reviving a cherished sea route between Nagapattinam, India, and Kankesanthurai, Sri Lanka. This historic connection, dormant for over 40 years, is poised to breathe new life into cultural exchange and travel between these two vibrant nations.</p>
            <h1 className="b-700 mtb-1-5">Our Story: Bringing Back the Past: </h1>
            <p>For centuries, this maritime route throbbed with life. To reignite the ferry service between the two countries, Mr S Niranjan met the then State Minister of Shipping Government of India Hon. Shri. Dr. Mansukh Mandaviya Ji and submitted a memorandum to initiate this service. Subsequently, a MoU was signed with Sagarmala Development Corporation Ltd. With these and other efforts with the Government of India and Sri Lanka, the inaugural ferry service between Naagapatinam and Kankesanthurai was launched in October 2023 by Hon Prime Minister of India Hon. Shri. Narendra Modi.</p>
            <div className='d-f c-gap-1 mt-1-5 sm-f-col r-gap-1'>
                <img src={img1} alt="" width="50%" className='br-r-1 sm-w-100' />
                <img src={img2} alt="" width="50%" className='br-r-1 sm-w-100' />
            </div>
            <p className="mt-1">Feel free to join us on this new adventure as we sail through the waters of progress, linking the past with the present!</p>
            <h1 className="b-700 mtb-1-5">Our Legacy Revived, A Future Embraced:</h1>
            <p>IndSri Ferry isn't just a ferry service; it's a bridge across time, reviving a journey steeped in shared heritage. Leading the way is Mr. S. Niranjan Nanthagopan, a passionate entrepreneur residing in Singapore and an Alumnus of the prestigious Indian Institute of Management of Ahmedabad. Inspired by his family's maritime past and his deep roots in Jaffna,  he's dedicated to fostering cultural connections and creating lasting memories for our passengers.</p>
            <h1 className="b-700 mtb-1-5">Our Visionary Leader:</h1>
            <p>At the helm of IndSri is Mr. S Niranjan Nanthagopan, a visionary entrepreneur who is an Accountant, a Proud Alumni of prestigious Indian Institute of Management of Ahmedabad and hailing from Jaﬀna and currently residing in Singapore. His roots deeply embedded in the cultural tapestry of Jaﬀna, Mr. Niranjan brings not just leadership but a passion for connecting the communities and creating meaningful bridges.</p>
            <h1 className="b-700 mtb-1-5">More Than a Ferry Service: Connecting Souls: </h1>
            <p>IndSri Ferry is not a mere transportation service; it's an experience that transcends borders and connects souls. The waters we navigate don't just separate two countries; they bring together the shores of two lands with a shared cultural heritage. Our ferry service is a symbolic union, weaving stories of unity and harmony. </p>
            <h1 className="b-700 mtb-1-5">Unforgettable Voyages, Seamless Journeys:</h1>
            <p>Embarking on a journey with IndSri Ferry means experiencing the epitome of modern maritime travel. Our ﬂeet is not just about reaching destinations; it's about providing a seamless, comfortable, and enjoyable journey. Aboard our modern fleet, the "Siva Ganga,", indulge in the panoramic views, savour the cultural nuances, and let the waves carry you to a world where the past and present harmoniously coexist.</p>
            <h1 className="b-700 mtb-1-5">Fostering Cultures, Creating Memories:</h1>
            <p>IndSri Ferry is a symbol of unity. We are committed to fostering cultural exchange, trade, and tourism. We aim to be more than just a mode of transport - we want to be the catalyst for memorable experiences and lifelong connections. As you navigate the waters with us, experience the magic of shared heritage. Each voyage with IndSri weaves a tapestry of understanding, bringing people and communities closer.</p>
            <h1 className="b-700 mtb-1-5">Revolutionizing Maritime Travel:</h1>
            <p>Embarking on a journey with IndSri Ferry means experiencing the epitome of modern maritime travel. Our ﬂeet is not just about reaching destinations; it's about providing a seamless, comfortable, and enjoyable journey. Indulge in the panoramic views, savour the cultural nuances, and let the waves carry you to a world where the past and present harmoniously coexist.</p>
            {/* <h1 className="b-700 mtb-1-5">Connecting Cultures, Creating Memories:</h1>
            <p>IndSri Ferry is committed to fostering cultural exchange, trade, and tourism. We aim to be more than just a mode of transport; we want to be the catalyst for memorable experiences and lifelong connections. Every voyage with IndSri is a chapter in the story of bringing people, communities, and countries closer together. </p> */}
            <h1 className="b-700 mtb-1-5">A Soulful Journey Awaits:</h1>
            <p>As you step aboard IndSri Ferry’s Siva Ganga, you're not just boarding a ferry; you're embarking on a soulful journey that links the hearts of two nations. Let the waves of our service carry you to a world where culture knows no boundaries, and connection is not just physical but transcendent. </p>
            <p className="mt-1-5"><span className="b-700">IndSri Ferry</span> - Where the sea becomes a canvas, and every journey is a stroke that paints a picture of shared heritage and unity. Join us as we navigate the waters of connectivity, forging paths that echo with the whispers of the past and the promises of the future.</p>

            <p className="b-700 mt-1-5">INDSRI FERRY SERVICES PRIVATE LIMITED</p>
            <p><span className="b-700">Reg No: </span>U61200TN2020PTC140333</p>
            <p><span className="b-700">GST No: </span>33AAFCI9579J1ZZ</p>
            <p className="b-700">Registered office : </p>
            <p>143/1, Uttamar Gandhi Road,</p>
            <p>Opp The Park Hotel,</p>
            <p>Thousand Lights West,</p>
            <p>Nungampakkam, </p>
            <p>Chennai - 600034,</p>
            <p>Tamil Nadu, India.</p>

        </div>
    )
}