import { useMediaQuery } from 'react-responsive';
export default function DateTimeCard({ fs, data }) {
    const isMob = useMediaQuery({ query: '(max-width:768px)' })
    return (
        <div className="d-f f-a-c f-spb">
            <div className="d-f f-col">
                <span className={fs.date + " b-500"}>{data.dateOfTravel}</span>
                <span className={fs.time + " b-500"}>{data.voyageFromTime}</span>
                <span className={fs.date + " b-500"}>{data.voyageFrom}</span>
            </div>
            <div className="d-f sm-f-a-c">
                {
                    !isMob ?
                        <><span style={{ lineHeight: "1rem" }}>.......&nbsp;</span>
                            <span >{data.duration}</span>
                            <span style={{ lineHeight: "1rem" }}>&nbsp;.......</span>
                        </>
                        : <><span style={{ lineHeight: "1rem" }}>-&nbsp;</span>
                            <span className='fs-12px'>{data.duration}</span>
                            <span style={{ lineHeight: "1rem" }}>&nbsp;-</span>
                        </>}
            </div>
            <div className="d-f f-col">
                <span className={fs.date + " b-500"}>{data.dateOfTravel}</span>
                <span className={fs.time + " b-500 txt-a-r"}>{data.voyageToTime}</span>
                <span className={fs.date + " b-500 txt-a-r"}>{data.voyageTo}</span>
            </div>
        </div>
    )
}