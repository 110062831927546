import { useState } from "react";
import ChangeDateProcess from "../components/changeDate/ChangeDateProcess";
import ChangeDate from "../components/changeDate/ChangeDate";
import { useRef } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
export default function ChangeDatePage() {
    const isMob = useMediaQuery({ query: '(max-width:1024px)' })
    const { state } = useLocation();
    const SearchRef = useRef(null)
    const [date, setDate] = useState({
        dateOfTravel: "",
        voyageFrom: "",
        voyageTo: "",
        returnDateOfTravel: "",
        returnVoyageFrom: "",
        returnVoyageTo: ""
    });
    const [openSearch, setOpenSearch] = useState(false)
    const style = {
        display: openSearch ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
        height: '100%',
        backgroundColor: 'rgb(0,0,0,.4)'
    }
    return (
        <>
            <ChangeDateProcess date={date} setDate={setDate} setOpenSearch={setOpenSearch} />
            {<div className="sm-p-abs">
                {/* {(openSearch && !isMob) && <div className="p-c sm-h-100 sm-of sm-w-100" ref={SearchRef} style={style} onMouseUp={(e) => {
                    if (SearchRef.current == e.target) setOpenSearch(false);
                }
                }>
                    <ChangeDate searchedParams={state ? state.fields : ''} />
                </div>
                } */}
                {
                    openSearch && (<Navigate to="/change-date" state={state ? state.fields : ''} replace={true} />)
                }

            </div >}
        </>
    )
}