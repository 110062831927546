import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useRef } from 'react';
import { ChangeDatecontext } from '../../ProcessContext';
export default function VoyagesProcess({ setOpenSearch }) {
    // const { state } = useLocation();
    const processSecondRef = useRef();
    const processThirdRef = useRef();
    const {
        setProcess,
        date,
        showSearchBtn,
    } = useContext(ChangeDatecontext);
    useEffect(() => {
        setProcess({
            processSecond: processSecondRef.current,
            processThird: processThirdRef.current
        })
    }, []);
    return (
        <div className="fade" style={{ background: "#D9D9D9" }}>
            <div className="d-f m-a f-spb a-i-c" style={{ width: "1088px", height: "177px" }}>
                <div className='d-f f-col r-gap-1-5'>
                    <div className="d-f a-i-c c-gap-1 rem">
                        <div className=" fs-20px b-500 d-f c-cont process-round white primary-bg">1</div>
                        <h1 className="fs-20px b-500">Plan Voyage</h1>
                    </div>
                    <div className="d-f">
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.dateOfTravel}</span>
                            <span className="fs-1-5 b-500">{date.voyageFrom}</span>
                        </div>
                        <div className="d-f a-i-c">.......&nbsp;<span>Departure</span>&nbsp;.......</div>
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.dateOfTravel}</span>
                            <span className="fs-1-5 b-500 txt-a-r">{date.voyageTo}</span>
                        </div>
                    </div>
                </div>
                <div className='d-f f-col r-gap-1-5'>
                    <div className="d-f a-i-c c-gap-1 rem">
                        <div ref={processSecondRef} className="fs-20px b-500 d-f c-cont process-round primary">2</div>
                        <h1 className="fs-20px b-500">Change & Book</h1>
                    </div>
                    <div className="d-f" style={{ height: '57px' }}>
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.returnDateOfTravel}</span>
                            <span className="fs-1-5 b-500">{date.returnVoyageFrom}</span>
                        </div>
                        <div className="d-f a-i-c">{date.returnDateOfTravel && <>.......&nbsp;<span>Return</span>&nbsp;.......</>}</div>
                        <div className="d-f f-col">
                            <span className="fs-14px b-500">{date.returnDateOfTravel}</span>
                            <span className="fs-1-5 b-500 txt-a-r">{date.returnVoyageTo}</span>
                        </div>
                    </div>
                </div>
                <div className='d-f f-col r-gap-1-5'>
                    {/* <div className="d-f a-i-c c-gap-1 rem">
                        <div ref={processThirdRef} className="fs-20px b-500 d-f c-cont process-round primary">3</div>
                        <h1 className="fs-20px b-500">Confirm & Pay</h1>
                    </div> */}
                    <div className="txt-a-r">
                        {<button className='br-btn primary pointer' style={{ visibility: `${showSearchBtn ? 'visible' : 'hidden'}` }} onClick={() => setOpenSearch(true)}>Modify Date</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}