import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../icons/Loader";
import { OpenEye, CloseEye } from "../icons/eye"
import Err from "./Err";
import { getCurrency } from "../../api/utils";
import { countries } from "../../data/country";
import TermsAndConditions from "../../pages/TermsConditions";
import { BackBtn } from "../btn";
import { useMediaQuery } from 'react-responsive';
import successImg from '../../assets/images/success.png';
export default function Register() {
    const isMob = useMediaQuery({ query: '(max-width:1024px)' })
    const [currentCountry, setCurrentCountry] = useState({
        dialCode: '',
        flag: ''
    })
    const navigate = useNavigate();
    const [show, setShow] = useState({
        password: true,
        confirmPassword: true
    });
    const [showCountry, setShowCountry] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [agree, setAgree] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [resErr, setResErr] = useState({ message: "" });
    const [showTermsConditions, setShowTermsConditions] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false)
    const [fields, setFields] = useState({
        shortName: "",
        email: "",
        confirmEmail: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        chargeCurrency: "",
        countryCode: ""
    })
    const [err, setErr] = useState({
        shortName: "",
        email: "",
        confirmEmail: "",
        mobile: "",
        password: "",
        confirmPassword: ""
    })
    const [validation, setValidation] = useState({
        shortName: false,
        email: false,
        confirmEmail: false,
        mobile: false,
        password: false,
        confirmPassword: false
    })
    console.log(fields)
    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        let errName = name;
        if (name == 'confirmEmail') errName = "Confirm email";
        else if (name == 'confirmPassword') errName = 'Confirm password';
        else if (name == 'shortName') errName = 'Short name';
        else if (name == 'email') errName = 'Email';
        else if (name == 'mobile') errName = 'Mobile';
        else if (name == 'password') errName = "Password"
        let validateEmpty = value.length == 0 ? `${errName} field is empty` : false;
        let validate = ''
        switch (name) {
            case 'shortName':
                if (validateEmpty) setErr({ ...err, [name]: validateEmpty })
                else setErr({ ...err, [name]: "" })
                break;
            case 'email':
                if (!validateEmpty) {
                    validate = fields.confirmEmail.toLocaleLowerCase() !== value.toLocaleLowerCase() ? 'Email not match' : false;
                    if (validate) setErr({ ...err, confirmEmail: validate, email: "" })
                    else setErr({ ...err, confirmEmail: "", email: "" })
                } else setErr({ ...err, [name]: validateEmpty, confirmEmail: "" })
                break;
            case 'confirmEmail':
                if (!validateEmpty) {
                    validate = fields.email.toLocaleLowerCase() !== value.toLocaleLowerCase() ? 'Email not match' : false;
                    if (validate) setErr({ ...err, confirmEmail: validate })
                    else setErr({ ...err, [name]: "" })
                } else setErr({ ...err, [name]: "" })
                break;
            case "mobile":
                if (!validateEmpty) {
                    validate = isNaN(value) ? 'Please enter number' : false;
                    if (!validate) {
                        validate = value.length > 12 ? "Numbers too long" : false
                        if (validate) setErr({ ...err, mobile: validate })
                        else {
                            validate = value.length < 7 ? "Numbers too short" : false
                            if (validate) setErr({ ...err, mobile: validate })
                            else setErr({ ...err, mobile: "" })
                        }
                    } else setErr({ ...err, mobile: validate })
                } else setErr({ ...err, [name]: validateEmpty })
                break;
            case 'password':
                if (!validateEmpty) {
                    validate = value.length < 8 ? 'Password must be at least 8 characters' : false;
                    if (!validate) {
                        validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : 'include symbol, numbers and Capital characters'
                        if (!validate) {
                            validate = fields.confirmPassword !== value ? 'Password not match' : false;
                            setErr({ ...err, confirmPassword: validate, password: "" })
                        } else setErr({ ...err, password: validate })
                    } else setErr({ ...err, password: validate })
                } else setErr({ ...err, [name]: validateEmpty, confirmPassword: "" })
                break;
            case 'confirmPassword':
                if (!validateEmpty) {
                    // validate = value.length < 8 ? 'password is too short' : false;
                    // if (!validate) {
                    // validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : 'missing symbol, numbers and Capital characters'
                    // if (!validate) {
                    validate = fields.password !== value ? 'password not match' : false;
                    if (!validate) setErr({ ...err, [name]: "" })
                    else setErr({ ...err, [name]: validate })
                    // }
                    // }
                } else setErr({ ...err, [name]: "" })

                break;
        }
        if (validateEmpty) {
            // setErr({ ...err, [name]: validateEmpty })
            if (name == 'email' || name == 'confirmEmail') {
                validation.email = false;
                validation.confirmEmail = false;
            }
            if (name == 'password' || name == 'confirmPassword') {
                validation[name] = false;
            }
            setValidation({ ...validation, [name]: false })
            setSubmit(false)
        }
        else {
            if (!validate) {
                // setErr({ ...err, [name]: "" })
                // setErr({ ...err, [name]: "" })
                validation[name] = true;
                if (name == 'email' || name == 'confirmEmail') {
                    // setErr({ ...err, email: "", confirmEmail: "" })
                    validation.email = true;
                    validation.confirmEmail = true;
                }
                if (name == 'password' || name == 'confirmPassword') {
                    // setErr({ ...err, password: "", confirmPassword: "" })
                    validation.password = true;
                    validation.confirmPassword = true;
                }
                let check = true;
                for (let i in validation) {
                    check &&= validation[i]
                }
                if (check && agree) { setSubmit(true); }
            }
            else {
                validation[name] = false;
                setSubmit(false);
            }
        }
        setFields({ ...fields, [name]: value })
    }
    function checkSubmit() {
        let check = true;
        for (let i in validation) {
            check &&= validation[i]
        }
        return check
    }
    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        const data = {
            Username: fields.shortName,
            Email: fields.email,
            PhoneNumber: fields.mobile,
            Password: fields.password,
            chargeCurrency: fields.chargeCurrency,
            countryCode: fields.countryCode
        }
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + "/Authenticate/register", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            // setResErr(result.message)
            if (response.status == 200) {
                setIsRegistered(true)
                setTimeout(() => {
                    setIsRegistered(false)
                    navigate('/verify', { state: true })
                }, 4000)
            }
            else throw result
        } catch (err) {
            setResErr({ message: err.message, alertMessage: err.alertMessage })
        } finally {
            setTimeout(() => setSubmitting(false), 1000)

        }
    }
    const style = {
        container: {
            maxWidth: "1088px",
            boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px'
        },
        input: {
            height: "48px",
            borderRadius: '8px',
            backgroundColor: "var(--inputGray)",
            border: 'none',
            paddingLeft: "1.5rem"
        },
        btn: {
            width: '171px',
            height: '48px',
            opacity: (submitting || !submit) ? .5 : 1
        }
    }
    useEffect(() => {
        getCurrency().then(data => {
            if (data) {
                const getCountry = countries.find(item => data.country_calling_code == item.dial_code)
                setCurrentCountry({
                    dialCode: getCountry.dial_code,
                    flag: getCountry.flag
                })
                setFields({
                    ...fields,
                    countryCode: getCountry.dial_code,
                    chargeCurrency: data.currency
                })
            }

        }
        )

    }, []);
    return (
        <div id='register' className="smd-box-shadow sm-br-r-n m-a md-br-r-n" style={{ ...style.container, marginBottom: isMob ? 'auto' : '2rem', padding: isMob ? '1rem' : '2rem' }}>
            <h1 className="primary fs-20px b-500 sm-txt-c smd-mt-1">Join & Start Your Seafaring Journey!</h1>
            <p className="gray fs-14px sm-txt-c">Create an account for easy booking & personalised experiences.</p>
            <form onSubmit={handleSubmit} className="mt-1-5 p-r">
                <div>
                    <h1 className="fs-20px b-500 mb-1 sm-fs-1 sm-txt-c">Personal Information</h1>
                    <label className="b-500 sm-fs-12px" htmlFor="">Short Name<span className="red b-500">*</span></label><br />
                    <input onChange={handleChange} className="mt-8px" style={style.input} type="text" name="shortName" placeholder="Short Name" />
                    {err.shortName && (<><br /><span className="red">{err.shortName}</span></>)}

                </div>
                <div className="mtb-1">
                    <h1 className="fs-20px b-500 mb-1 sm-fs-1 sm-txt-c">Contact Information</h1>
                    <ul className="gray md-d-n">
                        <li className="fs-14px">The tickets you will book in the future will be sent to the same email address you provide below. </li>
                        <li className="fs-14px">  Avoid providing temporary email address.</li>
                    </ul>
                </div>
                <div className="d-f md-f-col f-spb f-wrap r-gap-1">
                    <div>
                        <label className="b-500 sm-fs-12px" htmlFor="">Email Address<span className="red">*</span></label><br />
                        <input onChange={handleChange} className="mt-8px" autoComplete="new-password" style={style.input} type="email" name="email" placeholder="Email Address" />
                        {err.email && (<><br /><span className="red">{err.email}</span></>)}
                    </div>
                    <div>
                        <label className="b-500 sm-fs-12px" htmlFor="">Confirm Email Address<span className="red">*</span></label><br />
                        <input onChange={handleChange} className="mt-8px" style={style.input} type="email" name="confirmEmail" placeholder="Confirm Email Address" />
                        {err.confirmEmail && (<><br /><span className="red">{err.confirmEmail}</span></>)}
                    </div>
                </div>
                <div className="mt-1">
                    <label className="b-500 sm-fs-12px" htmlFor="">Mobile Number<span className="red">*</span></label><br />
                    <div className="d-f sm-f-spb c-gap-1 mx-w-448px">
                        <div>
                            <div className="inp-n-w mt-8px d-f f-j-c f-a-c c-gap-1 pointer" style={{ width: "109px" }} onClick={() => setShowCountry(!showCountry)}>
                                <span>{currentCountry.flag} {currentCountry.dialCode}</span>
                                <svg style={{ transform: showCountry ? 'rotateX(180deg)' : '' }} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 1L5 5L1 1" stroke="#2500BA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            {
                                showCountry && <><CountryLists fields={fields} setFields={setFields} setCurrentCountry={setCurrentCountry} setShowCountry={setShowCountry} />
                                    <div style={{ width: '100%', height: "100%", position: 'fixed', top: 0, left: 0 }} onClick={() => setShowCountry(false)}></div>
                                </>
                            }
                        </div>
                        {/* <input onChange={handleChange} className="mr-1 mt-8px" style={{ ...style.input, width: "60px", paddingLeft: '1rem' }} type="text" name="countryCallingCode" defaultValue={fields.countryCallingCode} /> */}
                        <div style={{ flexGrow: 1 }}>
                            <input onChange={handleChange} className="mt-8px" style={{ ...style.input, width: "100%" }} type="text" name="mobile" placeholder="Mobile Number" />
                            {err.mobile && (<><br /><span className="red">{err.mobile}</span></>)}
                        </div>
                    </div>

                </div>

                <div className="mt-2 mb-1-5">
                    <h1 className="fs-20px b-500 mb-1 sm-fs-1 sm-txt-c">Create Password</h1>
                    <div className="d-f md-f-col f-spb f-wrap r-gap-1">
                        <div >
                            <label className="b-500 sm-fs-12px" htmlFor="">Password<span className="red">*</span></label><br />
                            <div style={{ position: 'relative' }} className="mt-8px">
                                <input onChange={handleChange} style={{ ...style.input }} autoComplete="new-password" type={show.password ? 'password' : 'text'} name="password" placeholder="Password" />
                                <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, password: !show.password })} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, right: '1rem' }}>
                                    {show.password ? <CloseEye /> : <OpenEye />}
                                </span>
                            </div>

                            {err.password && (<><span className="red">{err.password}</span></>)}

                        </div>
                        <div>
                            <label className="b-500 sm-fs-12px" htmlFor="">Confirm Password<span className="red">*</span></label><br />
                            <div style={{ position: 'relative' }} className="mt-8px">
                                <input onChange={handleChange} style={style.input} type={show.confirmPassword ? 'password' : 'text'} name="confirmPassword" placeholder="Confirm Password" />
                                <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, confirmPassword: !show.confirmPassword })} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, right: '1rem' }}>
                                    {show.confirmPassword ? <CloseEye /> : <OpenEye />}
                                </span>
                            </div>
                            {err.confirmPassword && (<><span className="red">{err.confirmPassword}</span></>)}

                        </div>
                    </div>
                </div>
                <p className="fs-14px sm-txt-c">Your email is your username and login ID for this account.</p>
                <div className="d-f mt-1 mb-1-5 sm-f-j-c">
                    <input onChange={() => {
                        setAgree(!agree);
                        setSubmit(!agree && checkSubmit())
                    }} className="mr-2" id="radio" style={{ height: "20px", width: "20px" }} type="checkbox" name="conditions" required checked={agree} />
                    <span><label className="b-500 sm-fs-14px" htmlFor="radio">Accept </label><span className="primary b-500 pointer sm-fs-14px" onClick={() => setShowTermsConditions(true)}>Terms & Conditions</span></span><br />
                </div>
                <div className="d-f c-gap-1 sm-f-j-c">
                    <button className="p-r primary-bg white br-n br-r-8 pointer b-500" style={style.btn} disabled={!submit}>Register {submitting && <Loader />}</button>
                    <button className="pointer br-btn" style={{ width: '171px', height: '48px', }} onClick={() => navigate('/')}>Cancel</button>
                </div>
            </form>
            {resErr.message && <div className="overlay"><Err data={resErr} click={() => setResErr("")} /></div>}
            {showTermsConditions && <div className="overlay p-1" style={{ overflow: 'scroll' }}><TermsAndConditions setShowTermsConditions={setShowTermsConditions} isRegister={true} /></div>}
            {isRegistered && <Success />}
        </div>
    )
}

function CountryLists({ fields, setFields, setCurrentCountry, setShowCountry }) {
    const [searchKey, setSearchKey] = useState("");
    return (
        <div className="list-items shadow p-a bg-white md-w-100 mx-w-448px" style={{ width: "150px", padding: '.5rem', borderRadius: "4px", zIndex: 1 }}>
            <input onChange={(e) => setSearchKey(e.target.value.toUpperCase())} type="text" placeholder="Search" style={{ width: "100%", borderRadius: "4px", border: '2px solid #cecdcd' }} />
            <ul style={{ listStyle: 'none', padding: 0, height: '200px', overflow: "scroll", marginTop: '.5rem' }}>
                {
                    countries.map((item, index) => {
                        if (item.name.toUpperCase().indexOf(searchKey) > -1) {
                            return <li key={index} onClick={() => {
                                setCurrentCountry({
                                    dialCode: item.dial_code,
                                    flag: item.flag
                                });
                                setShowCountry(false)
                                setFields({ ...fields, countryCode: item.dial_code })
                            }
                            } className="pointer" > {item.flag} &nbsp;{item.name}</li>
                        }
                    })
                }
            </ul>
        </div >
    )
}

function Success() {
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ maxWidth: '450px', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={successImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c mt-1'>
                        <p className='primary b-500'>You have Registered successfully!</p>
                        <p className="gray" style={{ marginTop: ".5rem" }}>Continuing to your verification....</p>
                        {/* {data.status == "failed" && <button className='pointer msg-btn mt-1' onClick={() => window.location.assign('/dashboard')}>ok</button>} */}
                    </div>
                </div>
            </div>
        </div>
    )
}