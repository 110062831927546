import { useMediaQuery } from 'react-responsive';
import DetailCard from "../components/common/DetailCard";
import MobDetailCard from '../components/mobile/DetailCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPaxDetail } from '../api/api';
import { useEffect, useState } from 'react';
import Loader from '../components/icons/Loader';
import Ticket from '../components/common/Ticket';
import successImg from '../assets/images/success.png';
import failImg from '../assets/images/fail.png';
const html2pdf = require('html2pdf.js');
// import { jsPDF } from "jspdf";
// var domToPdf = require('dom-to-pdf');;
export default function Detail() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isCompleted, setIsCompleted] = useState(false);
    const [openTicket, setOpenTicket] = useState(false);
    const [loader, setLoader] = useState(true);
    const [msg, setMsg] = useState({
        status: "success",
        message: '',
        alertMessage: ''
    });
    const [ticketData, setTicketData] = useState({
        bookingId: '',
        passengerCount: '',
        completedPaxes: [],
        viewDetails: {
            chargeCurrency: "USD"
        },
        luggageInfo: {
            luggageOneWay: 0,
            luggageReturnWay: 0
        },
        seatInfo: {
            seatListOneWay: [],
            seatListReturnWay: []
        },
        menuInfo: {
            menuOneWay: [],
            menuReturnWay: []
        },
        fareDetails: {
            baseFare: 0,
            optionalServices: 0,
            taxes: 0,
            totalAmount: 0
        },
        fareBreakDown: {
            portLevy: [],
            travel: {
                baseFare: 0,
                taxes: []
            },
            food: {
                baseFare: 0,
                taxes: []
            },
            luggage: {
                baseFare: 0,
                taxes: []
            }
        },
        userName: '',
        phoneNumber: '',
        email: ''
    })
    const isMob = useMediaQuery({ query: '(max-width:744px)' });
    const isDesk = useMediaQuery({ query: '(min-width: 745px)' });
    const isMobDownload = useMediaQuery({ query: '(max-width:1024px)' });
    useEffect(() => {
        getPaxDetail({ bookingId: state.bookingId }).then(data => {
            if (data) {
                const paxCount = []
                if (data.passengerCount.adultCount) paxCount.push(data.passengerCount.adultCount + ' ADT')
                if (data.passengerCount.childCount) paxCount.push(data.passengerCount.childCount + ' CHLD')
                if (data.passengerCount.infantCount) paxCount.push(data.passengerCount.infantCount + ' INFT')
                setTicketData({
                    bookingId: state.bookingId,
                    passengerCount: paxCount,
                    completedPaxes: data.bookingPax,
                    viewDetails: data.viewDetails,
                    seatInfo: data.seatInfo,
                    luggageInfo: data.luggageInfo,
                    seatInfo: data.seatInfo,
                    menuInfo: data.menuInfo,
                    fareDetails: data.fareDetails,
                    fareBreakDown: data.fareBreakDown,
                    dateOfIssue: data.dateOfIssue,
                    userName: data.userName,
                    phoneNumber: data.phoneNumber,
                    email: data.email
                })
            }
            // if (data.completedPaxes.length == 0) {
            //     const date = {
            //         dateOfTravel: data.viewDetails.dateOfTravel,
            //         voyageFrom: data.viewDetails.voyageFrom,
            //         voyageTo: data.viewDetails.voyageTo,
            //         returnDateOfTravel: data.viewDetails.dateOfTravelReturn,
            //         returnVoyageFrom: data.viewDetails.voyageFromReturn,
            //         returnVoyageTo: data.viewDetails.voyageToReturn
            //     }
            //     navigate('/voyages/passenger-dts', { state: { bookingId: data.viewDetails.bookingId, date: date } });
            // }
            setLoader(false);
        })
    }, [])

    function ticketDownload() {
        var opt = {
            margin: 0,
            filename: ticketData.bookingId.join('-') + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        var element = document.querySelector("#elementId");
        html2pdf(element, opt)
    }
    async function emailTicket() {
        setLoader(true)
        const bookingId = state.bookingId;
        const url = process.env.REACT_APP_DOAMIN + '/ticket/download';
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    cCode: bookingId[0],
                    dCode: bookingId[1],
                    year: bookingId[2],
                    sNo: bookingId[3]
                })
            })
            // if (response.status == 200) navigate(`/ticket/download/${bookingId[0]}/${bookingId[1]}/${bookingId[2]}/${bookingId[3]}`);
            if (response.status == 200) {
                setMsg({
                    status: 'success',
                    message: "Your eTicket has been emailed to you successfully!",
                    alertMessage: "Please check your inbox of your given email..."
                })
            } else {
                setMsg({
                    status: 'failed',
                    message: "Something went wrong",
                    alertMessage: "Please try again"
                })
            }
            throw 'error'
        } catch (err) {
            return "";
        } finally {
            setTimeout(() => {
                setLoader(false)
                setMsg({ status: "success", message: "", alertMessage: "" });
            }, 5000)
        }
    }
    return (
        <div className="m-a" style={{ maxWidth: '1088px ', padding: "1.5rem 1rem" }}>
            <div className="d-f f-spb f-col f-a-c r-gap-1">
                <div className='d-f c-gap-1'>
                    {!isMobDownload && <h1 className="pointer fs-20px b-500 sm-fs-1" style={{ textDecoration: 'underline' }} onClick={() => ticketDownload()}>Download Ticket</h1>}
                    <h1 className="pointer fs-20px b-500 sm-fs-1" style={{ textDecoration: 'underline' }} onClick={() => emailTicket()}>Email Ticket</h1>
                </div>
                <div className="d-f sm-f-col sm-r-gap-1" style={{ columnGap: '3rem' }}>
                    <h1 className="fs-20px b-500 sm-fs-1">Details of Booking No: <span className="primary fs-20px sm-fs-1">{ticketData.bookingId && ticketData.bookingId.join('-')}</span></h1>
                    <h1 className='fs-20px sm-fs-1 sm-txt-c'>Pax(s): <span className="primary fs-20px sm-fs-1">{ticketData.viewDetails && ticketData.viewDetails.paxCount}</span></h1>
                </div>
            </div>
            <div className="mt-2">
                {(isDesk && ticketData.completedPaxes.length > 0) && ticketData.completedPaxes.map((item, index) => <div key={index} className='mb-2 d-f f-j-c f-a-c c-gap-1'><DetailCard data={item} commonData={ticketData.viewDetails} /></div>)}
                {(isMob && ticketData.completedPaxes.length > 0) && ticketData.completedPaxes.map((item, index) => <div key={index} className='mb-2'><MobDetailCard data={item} commonData={ticketData.viewDetails} /><div className='txt-a-c'></div></div>)}

            </div>
            <div className="mt-2">
                {ticketData.completedPaxes.length > 0 &&
                    <div className='d-f f-j-c c-gap-2 f-wrap'>
                        {!isMobDownload && <button className='btn pointer' style={{ width: "256px" }} onClick={() => ticketDownload()}>Download Ticket</button>}
                        <button className={`pointer ${isMob ? 'btn' : "br-btn"}`} style={{ width: "256px" }} onClick={() => emailTicket()}>Email Ticket</button>
                    </div>}
                {/* {(isMob && ticketData.completedPaxes.length > 0) && ticketData.completedPaxes.map((item, index) => <div key={index} className='mb-2'><MobDetailCard data={item} commonData={ticketData.viewDetails} /><div className='txt-a-c'><button className='btn mt-2'>Download Ticket</button></div></div>)} */}

            </div>
            {loader && <div className="overlay">
                <Loader color="white" />
            </div>}
            {/* <div style={{ display: "block" }}>
                <div id='ticket' className='p-1 m-a' style={{ width: "fit-content" }}>
                    <Ticket data={ticketData} />
                </div>
            </div> */}
            <div style={{ display: 'none' }}><Ticket data={ticketData} /></div>
            {msg.message && <Success data={msg} setMsg={setMsg} />}
        </div>
        // <div className='overlay'>
        //     <div className='p-c p-1 br-r-8 bg-white txt-a-c' style={{ maxWidth: '300px', height: "fit-content" }}>
        //         <p className='primary'>Please fill the passenger </p>
        //         <button className='pointer msg-btn mt-1' onClick={() => window.location.assign('/passenger-dts')}>ok</button>
        //     </div>
        // </div>
    )
}

function Success({ data, setMsg }) {
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ width: 'fit-content', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c'>
                        <p className='primary'>{data.message}</p>
                        <p>{data.alertMessage}</p>
                        {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}