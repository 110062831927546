import luggage from '../../assets/images/luggage.png';
import successImg from '../../assets/images/success.png';
import { Link } from "react-router-dom";
import ExtraLuggage from './ExtraLuggage';
import { useState } from "react";
import Loader from '../icons/Loader';
export default function LuggageSelection({ data, setProceedPaymentDts, proceedPaymentDts }) {
    const [show, setShow] = useState(false);
    const [amountBreakDown, setAmountBreakeDown] = useState({
        oneWay: {
            chargeCurrency: "INR",
            chargePrice: 0,
            chargePriceWithTax: 0,
            amountBreakDown: []
        },
        roundWay: {
            chargeCurrency: "INR",
            chargePrice: 0,
            chargePriceWithTax: 0,
            amountBreakDown: []
        }
    })
    const [lugInfo, setLugInfo] = useState({
        oneWayAmount: 0,
        oneWayWeight: 0,
        returnAmount: 0,
        returnWeight: 0,
    })
    const [isRemoved, setIsRemoved] = useState(false);
    const style = {
        wrapper: {
            maxWidth: '512px',
            minHeight: '360px',
            boxShadow: ' 0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: "1rem"
        },
        btn: {
            width: "196px",
            height: "48px",
            textDecoration: 'none',
            border: '1px solid var(--primaryColor)',
        }
    }
    return (
        <div>
            <h1 className="fs-20px b-500">Book for Extra Luggage (Optional)</h1>
            <div className="mt-2 d-f f-spb f-col" style={style.wrapper}>
                <img src={luggage} alt="" width="100%" height="192px" />
                <div className="d-f f-col p1-5 r-gap-1-5">
                    <p>Pack more memories! Secure your extra luggage effortlessly with our convenient booking options.</p>
                    {lugInfo.oneWayAmount + lugInfo.returnAmount == 0 && <button disabled={!(data.oneWayData.maxLuggage > 0)} onClick={() => setShow(true)} className="sm-m-a br-btn b-500 d-f f-a-c f-j-c primary br-r-8 pointer" style={{ ...style.btn, opacity: data.oneWayData.maxLuggage > 0 ? 1 : 0.4 }} >Extra Luggage</button>}
                </div>
                {(lugInfo.oneWayAmount + lugInfo.returnAmount > 0) && <Detail setIsRemoved={setIsRemoved} amountBreakDown={amountBreakDown} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} data={data} lugInfo={lugInfo} setLugInfo={setLugInfo} setShow={setShow} />}
            </div>
            <div className='overlay' style={{ display: show ? 'block' : 'none' }}>
                <ExtraLuggage count={data.noOfPax.adult} setAmountBreakeDown={setAmountBreakeDown} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} data={data} setLugInfo={setLugInfo} lugInfo={lugInfo} setShow={setShow} />
            </div>
            {isRemoved && <Success setIsRemoved={setIsRemoved} />}
        </div>
    )
}

function Detail({ setIsRemoved, amountBreakDown, setProceedPaymentDts, proceedPaymentDts, data, lugInfo, setLugInfo, setShow }) {
    const [showOrderTray, setShowOrderTray] = useState('');
    const [removeLoader, setRemoveLoader] = useState(false);
    const [removeType, setRemoveType] = useState('')
    function handleRemove(type) {
        setRemoveType(type)
        setRemoveLoader(true)
        setTimeout(() => {
            setRemoveLoader(false)
            setProceedPaymentDts({ ...proceedPaymentDts, paymentAmount: proceedPaymentDts.paymentAmount - lugInfo[type + 'Amount'] })
            setLugInfo({ ...lugInfo, [type + 'Amount']: 0, [type + 'Weight']: 0 })
            setIsRemoved(true)
        }, 2000)

    }
    return (
        <div className='p-1'>
            {lugInfo.oneWayWeight > 0 && <> <hr style={{ opacity: ".2" }} />< div className='d-f f-spb ptb-1 f-end'>
                <div className='d-f f-col'>
                    <p className='primary'>{data.oneWayData.voyageFrom} to {data.oneWayData.voyageTo}</p>
                    {/* <p onClick={() => setShowOrderTray('one')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>View Details</p> */}
                    <p onClick={() => setShowOrderTray('one')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>Extra Luggage: {lugInfo.oneWayWeight}kg</p>
                    <p>Total: <span className='b-600' style={{ fontFamily: "Arial, sans-serif" }}>{amountBreakDown.oneWay.chargePriceWithTax.toLocaleString("en-GB", { style: "currency", currency: amountBreakDown.oneWay.chargeCurrency })}</span></p>
                </div>
                <div className='txt-dec-u pointer p-r' onClick={() => handleRemove("oneWay")} style={{ minWidth: "35px", minHeight: "35px" }}>{(removeType == "oneWay" && removeLoader) ? <Loader color="#1F3167" /> : "Remove"}</div>
            </div>
            </>
            }
            {
                (data.round && lugInfo.returnWeight > 0) && <><hr style={{ opacity: ".2" }} />
                    <div className='d-f f-spb ptb-1 f-end'>
                        <div className='d-f f-col'>
                            <p className='primary'>{data.roundData.voyageFrom} to {data.roundData.voyageTo}</p>
                            {/* <p onClick={() => setShowOrderTray('two')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>View Details</p> */}
                            <p onClick={() => setShowOrderTray('two')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>Extra Luggage: {lugInfo.returnWeight}kg</p>
                            <p>Total: <span className='b-600' style={{ fontFamily: "Arial, sans-serif" }}>{amountBreakDown.roundWay.chargePriceWithTax.toLocaleString("en-GB", { style: "currency", currency: amountBreakDown.roundWay.chargeCurrency })}</span></p>
                        </div>
                        <div className='txt-dec-u pointer p-r' onClick={() => handleRemove('return')} style={{ minWidth: "35px", minHeight: "35px" }}>{(removeType == "return" && removeLoader) ? <Loader color="#1F3167" /> : "Remove"}</div>
                    </div></>
            }
            <div className='d-f f-spb f-end'>
                <button onClick={() => setShow(true)} className='pointer btn'>Change</button>
                <p className='txt-end'>Total Fare: <span className='b-600' style={{ fontFamily: "Arial, sans-serif" }}>{(lugInfo.oneWayAmount + lugInfo.returnAmount).toLocaleString('en-GB', { style: "currency", currency: data.oneWayData.chargeCurrency })}</span></p>
            </div>
            {showOrderTray && <div onClick={() => setShowOrderTray('')} className='overlay'>
                <OrderTray amountBreakDown={amountBreakDown} showOrderTray={showOrderTray} />
            </div>}

        </div >
    )
}

function OrderTray({ showOrderTray, amountBreakDown }) {
    console.log(amountBreakDown)
    const type = showOrderTray == "one" ? "oneWay" : "roundWay"
    return (
        <div className="p-1 p-c" style={{ maxWidth: "722px", height: "fit-content" }}>
            <div className="p-2 bg-white br-r-12">
                <div className='d-f f-spb'><p>Range</p><p>Amount</p></div>
                <hr />
                {
                    amountBreakDown[type].amountBreakDown.map((item, index) => <div key={index} className='d-f f-spb'><p>( {item.fromWeight} - {item.toWeight} ) Kg</p><p style={{ fontFamily: "Arial, sans-serif" }}>{item.amount.toLocaleString("en-GB", { style: "currency", currency: amountBreakDown[type].chargeCurrency })}</p></div>)
                }
                {/* <div className="d-f f-spb mt-1" ><span>Total Taxes</span><span>{tax.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span></div>
                <div className="d-f f-spb mt-1"><span>Total Price</span><span>{showOrderTray == "one" ? mealInfo.oneWayAmount.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency }) : mealInfo.roundWayAmount.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span></div>
             */}
                <hr />
                <div className='d-f f-spb'><p>Total Tax</p><p style={{ fontFamily: "Arial, sans-serif" }}>{(amountBreakDown[type].chargePriceWithTax - amountBreakDown[type].chargePrice).toLocaleString("en-GB", { style: "currency", currency: amountBreakDown[type].chargeCurrency })}</p></div>
                <hr />
                <div className='d-f f-spb'><p>Total Price</p><p style={{ fontFamily: "Arial, sans-serif" }}>{amountBreakDown[type].chargePriceWithTax.toLocaleString("en-GB", { style: "currency", currency: amountBreakDown[type].chargeCurrency })}</p></div>
                <hr />
                <hr />
            </div>
        </div>
    )
}
function Success({ setIsRemoved }) {
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ maxWidth: '450px', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={successImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c'>
                        <p className='primary'>You have successfully cancelled Extra Luggage!</p>
                        <p>Please continue your booking!</p>
                        <button className='pointer btn mt-1' onClick={() => setIsRemoved(false)} style={{ width: "120px" }}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}