import { useMediaQuery } from 'react-responsive';
import VoyageCard from '../desk/VoyageCard';
import MobVoyageCard from '../mobile/VoyageCard';
import TabVoyageCard from '../tab/VoyageCard';
export default function OneWay({ luggageInfo, voyages, book, setBook, type, setShowReturn }) {
    const isMob = useMediaQuery({ query: '(max-width:768px)' })
    const isTab = useMediaQuery({ minWidth: 769, maxWidth: 1279 })
    const isDesk = useMediaQuery({ query: '(min-width: 1280px)' })
    return (
        <>
            {
                (isMob && voyages) && voyages.map((item, index) => <MobVoyageCard luggageInfo={luggageInfo} isRound={false} book={book} setBook={setBook} type={type} key={index} data={item} setShowReturn={setShowReturn} />)
            }
            {
                (isTab && voyages) && voyages.map((item, index) => <TabVoyageCard luggageInfo={luggageInfo} isRound={false} book={book} setBook={setBook} type={type} key={index} data={item} setShowReturn={setShowReturn} />)
            }
            {
                (isDesk && voyages) && voyages.map((item, index) => <VoyageCard luggageInfo={luggageInfo} isRound={false} book={book} setBook={setBook} type={type} key={index} data={item} setShowReturn={setShowReturn} />)
            }
        </>
    );
}