import failImg from '../../assets/images/fail.png';
export default function PaymentFailed() {
    return (
        <div className="overlay">
            <div className="p-c p-1" style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className="p-1 br-r-12 bg-white">
                    {/* <h1 className="red fs-20px b-500">Error . . .</h1> */}
                    <div className='txt-a-c'><img src={failImg} alt="" width="80px" /></div>
                    <h1 className="b-500 primary txt-a-c mt-1">Oh! Your payment is not processed successfully!</h1>
                    <p className="txt-a-c" style={{ marginTop: ".5rem" }}>
                        Please provide correct payment method again.
                    </p>
                    <div className="txt-a-c mt-1">
                        <button className="btn pointer" style={{ width: '70px', height: '34px' }} onClick={() => window.location.assign('/')}>ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}