import failedImg from '../../assets/images/warning.png'
import { Link, useNavigate } from "react-router-dom";
export default function RePlanVoyages() {
    const navigate = useNavigate()
    return (
        <div className="p-c p-2 w-100 h-100 d-f f-j-c f-a-c">
            <div className="fade p-1-5 p-r d-f br-r-1 w-100 h-100" style={{ maxWidth: "896px", maxHeight: "438px", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="d-f f-col f-j-c f-a-c r-gap-2 m-a">
                    <img src={failedImg} alt="" style={{ width: "fit-content" }} />
                    <div>
                        <h1 className="b-500 txt-a-c">Please Re-enter the Voyages Plan!</h1>
                        <p className="txt-a-c gray">Please double-check your email – it's already associated with an existing account. If you've forgotten your password, you can reset it or contact our support for assistance.</p>
                    </div>
                    <div className="d-f c-gap-2 f-j-c f-wrap sm-r-gap-1">
                        <button onClick={() => navigate('/home')} className="btn pointer" style={{ width: "171px" }}>OK</button>
                    </div>
                </div>
            </div>
        </div >
    )
}