import { Link } from "react-router-dom"
import PaxDetailCard from "../common/PaxDetailCard"
import { useState } from "react"
export default function DetailCard({ data, commonData }) {
    const [show, setShow] = useState(false);
    const travelType = {
        1: 'One Way',
        2: 'Round Way'
    }
    const paxType = {
        1: 'Adult',
        2: 'Child',
        3: 'Infant'
    }
    const travelCssName = {
        1: 'one',
        2: 'round'
    }
    const style = {
        container: {
            width: '100%',
            height: 'fit-content',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '1rem',
            padding: '0 1.5rem  1rem 1.5rem'
        }
    }
    return (
        <div className="txt-a-c" style={style.container}>
            <div className="d-f f-spb w-100">
                <span className="bg-primary white plr-1 fs-12px b-500" style={{ width: 'fit-content', height: "fit-content", borderRadius: '0 0 4px 4px' }}>{commonData.voyageId}</span>
                <span className="fs-12px b-500 round plr-1" style={{ width: 'fit-content', height: "fit-content", borderRadius: '0 0 4px 4px', alignItems: "end" }}>{travelType[commonData.type]}</span>
            </div>
            <p className="b-600 mtb-1 fs-14px">{data.otherName}&nbsp;{data.sureName}</p>
            <div className="d-f f-j-c" style={{ columnGap: '3rem' }}>
                <div className="d-f f-col f-a-c">
                    <span className="fs-12px b-500">{commonData.dateOfTravel}</span>
                    <span className="fs-20px b-500">{commonData.voyageFrom} - {commonData.voyageTo}</span>
                    <span className="fs-12px b-500">{commonData.voyageFromTime} - {commonData.voyageToTime}</span>
                </div>
                {commonData.type == 2 && <div className="d-f f-col f-a-c">
                    <span className="fs-12px b-500">{commonData.dateOfTravelReturn}</span>
                    <span className="fs-20px b-500">{commonData.voyageFromReturn}-{commonData.voyageToReturn}</span>
                    <span className="fs-12px b-500">{commonData.voyageFromTimeReturn} - {commonData.voyageToTimeReturn}</span>
                </div>}
            </div>
            <div className="d-f f-spb mtb-1 f-j-c">
                <p className="fs-14px">Pax Type: <span className="fs-14px b-700">{paxType[data.passengerType]}</span></p>
                {/* <p className="fs-14px">Seat No: <span className="fs-14px b-700">N/A</span></p> */}
            </div>
            <p><span className="fs-14px">Ticket No: </span><span className="b-700">{commonData.bookingId && commonData.bookingId.map(item => "-" + item)}-{data.seqNo}</span></p>
            <div className="d-f f-spb mt-2">
                <Link className="primary fs-14px" onClick={() => setShow(!show)}>{show ? "Close detail" : "View details"}</Link>
                <span className="br-r-8 economy fs-14px" style={{ padding: '4px 8px' }}>{commonData.className}</span>
            </div>
            {show && <PaxDetailCard data={data} />}
        </div>
    )
}