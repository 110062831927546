import { useContext, useEffect, useRef } from 'react';
import { ChangeDatecontext } from '../../ProcessContext';
export default function TabVoyagesProcess({ setOpenSearch }) {
    const processSecondRef = useRef();
    const processThirdRef = useRef();
    const {
        setProcess,
        date,
        showSearchBtn,
    } = useContext(ChangeDatecontext);
    useEffect(() => {
        setProcess({
            processSecond: processSecondRef.current,
            processThird: processThirdRef.current
        })
    }, []);
    const style = {
        round: {
            width: '32px',
            height: '32px'
        },
        dateBox: {
            maxWidth: '159px',
            height: '97px',
            background: "#E9E9E9",
            width: '100%'
        }
    }
    return (
        <div className="sm-p-1 box-size d-f f-col f-spb" >
            <div className="d-f f-spb sm-c-gap-1">
                <div className="d-f f-col r-gap-1">
                    <div style={style.round} className="b-500 d-f c-cont process-round white primary-bg">1</div>
                    <h1 className="b-500">Plan Voyage</h1>
                </div>
                {/* <div className="d-f f-col f-a-c  r-gap-1">
                    <div ref={processSecondRef} style={style.round} className="b-500 d-f c-cont process-round primary">2</div>
                    <h1 className="b-500 txt-a-c">Travel Details</h1>
                </div> */}
                <div className="d-f f-col f-end r-gap-1">
                    <div ref={processThirdRef} style={style.round} className="b-500 d-f c-cont process-round primary">3</div>
                    <h1 className="b-500 sm-txt-a-e">Change & Book</h1>
                </div>
            </div>
            {date.dateOfTravel && <div className={`d-f mtb-1-5 ${date.returnDateOfTravel ? "f-spb" : "f-j-c"}`} >
                <div className="d-f f-col f-c f-spb p-1 br-r-8" style={style.dateBox}>
                    <span className="fs-14px b-500">{date.dateOfTravel}</span>
                    <div className="d-f">
                        <span className="fs-1-5 b-500">{date.voyageFrom}</span>
                        <span className="fs-1-5 b-500">-</span>
                        <span className="fs-1-5 b-500">{date.voyageTo}</span>
                    </div>
                </div>
                {date.returnDateOfTravel && <div className="d-f f-col f-c f-spb p-1 f-spb br-r-8" style={style.dateBox}>
                    <span className="fs-14px b-500">{date.returnDateOfTravel}</span>
                    <div className="d-f">
                        <span className="fs-1-5 b-500">{date.returnVoyageFrom}</span>
                        <span className="fs-1-5 b-500">-</span>
                        <span className="fs-1-5 b-500">{date.returnVoyageTo}</span>
                    </div>
                </div>}
            </div>}
            <div className="txt-a-c" style={{ display: `${showSearchBtn ? 'block' : 'none'}`, marginTop: date.dateOfTravel ? 'auto' : '2rem' }} >
                <button className='br-btn primary pointer' onClick={() => setOpenSearch(true)}>Modify Date</button>
            </div>
        </div >
    )
}