export default function TermsAndConditions({ setShowTermsConditions, isRegister = false }) {
    return (
        <div className="br-r-1 bg-white m-a h-100" style={{ overflow: 'auto', maxWidth: "896px", padding: "2rem 1.5rem", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <h1 className="fs-25px b-600" style={{ textAlign: 'center' }}>Terms & Conditions for IndSri Ferry Services Pvt Ltd - Ferry Ticket Booking System</h1>
            <p className="mt-1-5 ">Welcome to Sail IndSri, the official ticket booking platform for IndSri Ferry Services (Pvt) Ltd. These Terms and Conditions outline the rules and regulations for the use of our Web and Mobile Application Services, available on both Android and iOS platforms. By accessing and using Sail IndSri, you are agreeing to comply with and be bound by the following terms.</p>
            <ol className="pl-1">




            <li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Introduction</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em' }}>
      1. These terms and conditions shall govern your use of our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em',marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      1.1. By using our website, you accept these terms and conditions in full; accordingly, if you disagree with these terms and conditions or any part of these terms and conditions, you must not use our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em',marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      1.2. If you register with our website, submit any material to our website or use any of our website services, we will ask you to expressly agree to these terms and conditions.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em',marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      1.3. You must be at least 18 years of age to use our website; by using our website or agreeing to these terms and conditions, you warrant and represent to us that you are at least 18 years of age.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em',marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      1.4. Our website is not using cookies.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Licence to use website</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
   
    <li style={{ lineHeight: '1.5', marginBottom: '1em',marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.1. You may:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) View pages from our website in a web browser;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) Download pages from our website for caching in a web browser;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (c) Print pages from our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.2. Except as expressly permitted BY THE provisions of these terms and conditions, you must not download any material from our website or save any such material to your computer.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.3. You may only use our website for your own personal and business purposes, and you must not use our website for any other purposes.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.4. Except as expressly permitted by these terms and conditions, you must not edit or otherwise modify any material on our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.5. Unless you own or control the relevant rights in the material, you must not:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (a) Republish material from our website (including republication on another website);
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (b) Sell, rent or sub-license material from our website;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (c) Show any material from our website in public;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (d) Exploit material from our website for a commercial purpose; or
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (e) Redistribute material from our website.
</li>

<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      2.5. We reserve the right to restrict access to areas of our website, or indeed our whole website, at our discretion; you must not circumvent or bypass, or attempt to circumvent or bypass, any access restriction measures on our website.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Licence to use website</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-20px', paddingLeft: '20px' }}>
  3.1. You must not:
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (a) Use our website in any way or take any action that causes, or may cause, damage to the website or impairment of the performance, availability or accessibility of the website;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (b) Use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (c) Use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (d) Conduct any systematic or automated data collection activities including without limitation scraping, data mining, data extraction and data harvesting on or in relation to our website without our express written consent;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (e) Access or otherwise interact with our website using any robot, spider or other automated means, except for the purpose of search engine indexing;
</li>
<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
  (f) Use data collected from our website for any direct marketing activity (including without limitation email marketing, SMS marketing, telemarketing and direct mailing).
</li>


<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      3.2. You must not use data collected from our website to contact individuals, companies, or other persons or entities.
    </li>


<li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px' , textIndent: '-25px', paddingLeft: '20px' }}>
      3.3. You must ensure that all the information you supply to us through our website, or in relation to our website, is true, accurate, current, complete and non-misleading.
    </li>
</ul>
</li>

<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Registration and accounts</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
   
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      4.1. You may register for an account with our website by completing and submitting the account registration form on our website, and clicking on the verification link in the email that the website will send to you.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      4.2. You must not allow any other person to use your account to access the website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      4.3. You must notify us in writing immediately if you become aware of any unauthorised use of your account.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      4.4. You must not use any other person's account to access the website, unless you have that person's express permission to do so.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>User login details</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      5.1. If you register for an account with our website, you will be asked to choose a user ID (your email) and password.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      5.2. Your user ID must not be liable to mislead; you must not use your account or user ID for or in connection with the impersonation of any person.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      5.3. You must keep your password confidential.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      5.4. You must notify us in writing immediately if you become aware of any disclosure of your password.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      5.5. You are responsible for any activity on our website arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Cancellation and suspension of account</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
   
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      6.1. We may:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) Suspend your account;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) Cancel your account; and/or at any time in our sole discretion without notice or explanation.
    </li>
   
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      6.2. You may cancel/terminate your account on our website using your account control panel on the website.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Your content: licence</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      7.1. In these terms and conditions, "your content" means all works and materials that you submit to us or our website for storage, processing by, or transmission via, our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      7.2. You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, store the data you may provide to us.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      7.3. You hereby waive all your moral rights in your content to the maximum extent permitted by applicable law; and you warrant and represent that all other moral rights in your content have been waived to the maximum extent permitted by applicable law.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      7.4. You may edit your content to the extent permitted using the editing functionality made available on our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      7.5. Without prejudice to our other rights under these terms and conditions, if you breach any provision of these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may delete/suspend any or all of your content.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Your content: rules</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      8.1. You warrant and represent that your content will comply with these terms and conditions.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      8.2. Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person in each case in any jurisdiction and under any applicable law.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      8.3. Your content, and the use of your content by us in accordance with these terms and conditions, must not:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) be libelous or maliciously false;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) be obscene or indecent;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (c) infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (d) infringe any right of confidence, right of privacy or right under data protection legislation;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (e) constitute negligent advice or contain any negligent statement;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (f) constitute an incitement to commit a crime, instructions for the commission of a crime or the promotion of criminal activity;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (g) be in contempt of any court, or in breach of any court order;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (h) be in breach of racial or religious hatred or discrimination legislation;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (i) be blasphemous;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (j) be in breach of official secrets legislation;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (k) be in breach of any contractual obligation owed to any person.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Limited warranties</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      9.1. We do not warrant or represent:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) The completeness or accuracy of the information published on our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) That the material on the website is up to date; or
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (c) That the website or any service on the website will remain available.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      9.2. We reserve the right to discontinue or alter any or all of our website services, and to stop publishing our website, at any time in our sole discretion without notice or explanation; and save to the extent expressly provided otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any website services, or if we stop publishing the website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      9.3. To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to the subject matter of these terms and conditions, our website and the use of our website.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Breaches of these terms and conditions</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      10.1. Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) Send you one or more formal warnings;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) Temporarily suspend your access to our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (c) Permanently prohibit you from accessing our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (d) Block computers using your IP address from accessing our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (e) Contact any or all of your internet service providers and request that they block your access to our website;
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (f) Commence legal action against you, whether for breach of contract or otherwise; and/or
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (g) Suspend or delete your account on our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      10.2. Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking[ (including without limitation [creating and/or using a different account])].
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Variation</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      11.1. We may revise these terms and conditions from time to time.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      11.2. The revised terms and conditions shall apply to the use of our website from the date of publication of the revised terms and conditions on the website, and you hereby waive any right you may otherwise have to be notified of, or to consent to, revisions of these terms and conditions. OR We will give you written notice of any revision of these terms and conditions, and the revised terms and conditions will apply to the use of our website from the date that we give you such notice; if you do not agree to the revised terms and conditions, you must stop using our website.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      11.3. If you have given your express agreement to these terms and conditions, we will ask for your express agreement to any revision of these terms and conditions; and if you do not give your express agreement to the revised terms and conditions within such period as we may specify, we will disable or delete your account on the website, and you must stop using the website.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Assignment</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      12.1. You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms and conditions.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      12.2. You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of your rights and/or obligations under these terms and conditions.
    </li>
  </ul>
</li>

<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Severability</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      13.1. If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      13.2. If any unlawful and/or unenforceable provision of these terms and conditions would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.
    </li>
  </ul>
</li>

<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Third party rights</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      14.1. A contract under these terms and conditions is for our benefit and your benefit, and is not intended to benefit or be enforceable by any third party.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      14.2. The exercise of the parties' rights under a contract under these terms and conditions is not subject to the consent of any third party.
    </li>
  </ul>
</li>

<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Entire agreement</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      15.1. The terms and conditions together with our Privacy Policy shall constitute the entire agreement between you and us in relation to your use of our website and shall supersede all previous agreements between you and us in relation to your use of our website.
    </li>
  </ul>
</li>
<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Law and jurisdiction</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      16.1. These terms and conditions shall be governed by and construed in accordance with Indian Law.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      16.2. Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Tamil Nadu.
    </li>
  </ul>
</li>


<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Statutory and regulatory disclosures</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      17.1. We are registered in MCA, Government of India; you can find the online version of the register at mca.gov.in, and our registration number is U61200TN2020PTC140333.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      17.2. Our GST number is 33AAFCI9579J1ZZ.
    </li>
  </ul>
</li>
<li class="b-700 mt-1-5">
  <h1 class="b-700" style={{ marginBottom: '20px' }}>Our details</h1>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      18.1. This website is owned and operated by IndSri Ferry Services Private Limited.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      18.2. We are registered in Tamil Nadu, India under registration number U61200TN2020PTC140333, and our registered office is at 143/1, Uttamar Gandhi Road,

Opp The Park Hotel,

Thousand Lights West,

Nungampakkam,

Chennai - 600034,

Tamil Nadu, India..
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      18.3. Our principal place of business is at Chennai, Tamil Nadu, India.
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '20px', textIndent: '-25px', paddingLeft: '20px' }}>
      18.4. You can contact us:
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (a) By post, using the postal address given at: <a href="https://sailindsri.com/contact-us"> Contact Page </a>
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (b) By telephone, on +91 99521 99899
    </li>
    <li style={{ lineHeight: '1.5', marginBottom: '1em', marginLeft: '40px' }}>
      (c) By email, using bookings@sailindsri.com
    </li>
  </ul>
</li>







                
            </ol>
            {isRegister && <div className="txt-a-c mt-2">
                <button className="btn pointer" onClick={() => setShowTermsConditions(false)}>Continue</button>
            </div>}
        </div>
    )
}