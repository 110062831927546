export default function MenuIcon() {
    return (
        <>
            <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
                <path d="M2 14H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
                <path d="M2 8H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
            </svg>

        </>
    )
}