import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { OpenEye, CloseEye } from "../icons/eye";
import { useRef, useState } from "react";
import Loader from "../icons/Loader";
import Err from "./Err";
import logo from "../../assets/images/logo.png";
import bgLoginImg from "../../assets/images/landing-bg.png";
import appStore from "../../assets/images/app_store.svg";
import playStore from "../../assets/images/play_store.svg";
import pax100 from "../../assets/images/eta.png";
import { useMediaQuery } from "react-responsive";
import Discount from "../common/Discount";
export default function Login() {
  if (sessionStorage.getItem("clickOffer2") == null)
    sessionStorage.setItem("clickOffer2", 1);
  else if (sessionStorage.getItem("clickOffer" == 2))
    sessionStorage.setItem("clickOffer2", 2);
  const [offer, setOffer] = useState(sessionStorage.getItem("clickOffer2"));
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const isHeight = useMediaQuery({ query: "(max-height:740px)" });
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [resErr, setResErr] = useState("");
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const style = {
    bgContainer: {
      backgroundImage: `url(${bgLoginImg})`,
      marginTop: !isMob ? "64px" : "auto",
      padding: isMob ? "0" : "1rem 0",
      width: "100%",
      height: isMob ? "100%" : "calc(100% - 98px)",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundSize: "100% 100%",
      justifyContent: "space-around",
    },
  };
  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.value });
  }
  async function handleSumbit(e) {
    e.preventDefault();
    setSubmit(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_DOAMIN + "/Authenticate/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "Application/json",
          },
          body: JSON.stringify({
            Email: fields.email,
            Password: fields.password,
          }),
        }
      );
      const result = await response.json();
      if (response.status == 200) {
        localStorage.setItem("token", result.token);
        localStorage.setItem("username", result.userName);
        localStorage.setItem("email", result.email);
        localStorage.setItem("phoneNumber", result.phoneNumber);
        localStorage.setItem("role", result.role);
        if (result.role != 1) {
          localStorage.removeItem("book");
          localStorage.setItem("chargeCurrency", result.chargeCurrency);
          window.location.assign("/dashboard");
        } else {
          if (result.chargeCurrency != localStorage.getItem("chargeCurrency")) {
            window.location.assign("/home");
          } else {
            if (localStorage.getItem("book"))
              window.location.assign("/voyages/fare-summary");
            else window.location.assign("/home");
          }
          localStorage.setItem("chargeCurrency", result.chargeCurrency);
        }
      } else {
        throw result;
      }
    } catch (err) {
      setSubmit(false);
      setResErr({ message: err.message, alertMessage: "" });
    }
  }
  if (localStorage.getItem("token"))
    return window.location.assign("/dashboard");
  return (
    <div id="login-bg" style={style.bgContainer} className="d-f f-a-c m-a">
      {offer == 1 && <Discount name="pax" img={pax100} setOffer={setOffer} />}
      {!isMob && (
        <div>
          <h1 className="b-700 white" style={{ fontSize: "64px" }}>
            Cruise the <br /> Palk Strait <br /> Swiftly!
          </h1>
          <h2 className="b-500 white">
            Your gateway to coastal connections,
            <br />
            linking shores and souls.
          </h2>
          <div className="d-f c-gap-1 mt-1">
            <img
              className="pointer"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.indsri.ferry&pcampaignid=web_share",
                  "_blank"
                )
              }
              src={playStore}
              alt=""
            />
            <img
              className="pointer"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/gb/app/sail-indsri/id6480496722",
                  "_blank"
                )
              }
              src={appStore}
              alt=""
            />
          </div>
        </div>
      )}
      <div
        id="login"
        className="bg-white sm-br-r-n fade d-f f-col sm-of-a smd-w-100"
        style={{ height: isHeight ? "100%" : "fit-content", overflowY: "auto" }}
      >
        <div style={{ padding: isMob ? "1rem" : "1.5rem 2rem" }}>
          {isMob && (
            <div className="txt-a-c smd-mt-1">
              <Link to="/">
                <img src={logo} alt="" width={isMob ? "128px" : "72px"} />
              </Link>
            </div>
          )}
          <div className="txt-a-c">
            <h1 className="fs-20px b-500 primary">
              To continue booking tickets
            </h1>
            <h2 className="gray">Log in to access your bookings!</h2>
          </div>
          <form onSubmit={handleSumbit} className="mt-1 d-f f-col">
            <div>
              <label htmlFor="" className="b-500">
                Email Address
              </label>
              <br />
              <input
                onChange={handleChange}
                style={{ padding: "14px 24px" }}
                className="fs-14px mt-8px input-gray gray w-100 h-48 br-r-8 br-n"
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              <span
                onClick={() =>
                  navigate("/forgot", { state: { isActive: true } })
                }
                className="txt-a-r mt-8px fs-14px pointer"
                style={{ float: "right" }}
              >
                Active User
              </span>
            </div>
            <div>
              <label htmlFor="" className="b-500">
                Password
              </label>
              <br />
              <div className="mt-8px d-f" style={{ position: "relative" }}>
                <input
                  onChange={handleChange}
                  style={{ padding: "14px 24px" }}
                  className="fs-14px input-gray gray w-100 h-48 br-r-8 br-n"
                  type={show ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  required
                />
                <span
                  className="pointer d-f h-100 f-a-c"
                  onClick={() => setShow(!show)}
                  style={{
                    position: "absolute",
                    right: 0,
                    marginRight: "12px",
                  }}
                >
                  {show ? <CloseEye /> : <OpenEye />}
                </span>
              </div>
            </div>
            <span
              onClick={() =>
                navigate("/forgot", { state: { isActive: false } })
              }
              className="txt-a-r black mt-8px fs-14px pointer"
            >
              Forgot Password?
            </span>
            {/* <span className="mt-1 txt-a-r">Forgot Password?</span> */}
            <div className="mt-1-5 sm-mt-1-5" style={{ textAlign: "center" }}>
              <button
                className="p-r m-a btn pointer"
                type="submit"
                value="Login"
                style={{ width: "171px" }}
              >
                Login {submit && <Loader color={"black"} />}
              </button>
            </div>
            {/* <input className="m-a mt-1 br-r-8 h-48 white primary-bg br-n pointer" type="submit" value="Login" style={{ width: "171px" }} /> */}
          </form>
          <hr className="mtb-1" />
          <div className="d-f f-wrap f-j-c c-gap-1">
            <p className="txt-a-c">New to our services?</p>
            <p className="txt-a-c">
              <Link
                to="/register"
                className="b-600 pointer primary"
                style={{ textDecoration: "none" }}
              >
                Join Now!
              </Link>
            </p>
          </div>
          <p className="txt-a-c mtb-1">To just check out the ticket fare?</p>
          <Link
            to="/home"
            className="br-btn m-a d-f f-a-c f-j-c br-r-8 h-48"
            style={{ textDecoration: "none", width: "171px" }}
          >
            Check as Guest
          </Link>
          {resErr.message && (
            <div className="overlay">
              <Err data={resErr} click={() => setResErr({ message: "" })} />
            </div>
          )}
          <div className="d-f c-gap-1 f-j-c mt-1">
            <Link to="/terms-conditions" className="txt-dec-n fs-12px primary">
              Terms & Conditions
            </Link>
            <Link to="/privacy-policy" className="txt-dec-n fs-12px primary">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
