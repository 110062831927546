import { useEffect, useState } from "react";
import { getSearchDetails } from "../../api/search";
import { getBookings } from "../../api/dashboard";
import searchImg from '../../assets/images/search.png';
import closeImg from '../../assets/images/close.png';
import { useMediaQuery } from 'react-responsive';
export default function SearchFilter({ setBookings, setLoader, fields, setFields }) {
    const isMob = useMediaQuery({ query: '(max-width:768px)' });
    const [port, setPort] = useState([]);
    const [submit, setSubmit] = useState(true);
    const [openFilter, setOpenFilter] = useState(false)
    // const [fields, setFields] = useState({
    //     fromDate: new Date().toLocaleDateString('en-GB'),
    //     toDate: "",
    //     fromPort: '',
    //     toPort: ''
    // });
    useEffect(() => {
        getSearchDetails().then(data => {
            if (data) {
                fields.fromPort = data.ports[0].portId
                fields.toPort = data.ports[1].portId
                setPort(data.ports)
            }
        })
    }, [])
    function handleChange(e) {
        let value = e.target.value;
        if (e.target.type == "date") value = new Date(value).toLocaleDateString('en-GB');
        setFields({ ...fields, [e.target.name]: value })
        console.log(fields);
    }
    function handleSubmit() {
        setLoader(true)
        getBookings(fields).then(data => {
            if (data) {
                setBookings(data.completedBooking)
                setLoader(false)
                setFields({ isSearch: true })
            } else setLoader(false)
        })
    }
    return (
        <div className="mt-2">
            <div className="d-f f-spb">
                <h1 className="fs-20px b-500">Search Bookings</h1>
                {
                    isMob && <div>
                        {!openFilter && <img src={searchImg} alt="" width="30px" onClick={() => setOpenFilter(true)} />}
                        {openFilter && <img src={closeImg} alt="" width="40px" onClick={() => setOpenFilter(false)} />}
                    </div>
                }
            </div>
            {(openFilter || !isMob) && <div className="smd-f-col smd-r-gap-1 shadow d-f f-spb p-2 br-r-1 mt-1-5 sm-a-i-c" style={{ alignItems: 'end', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                {/* <div className="smd-w-100">
                    <label htmlFor="">Booking No</label> <br />
                    <input className="search-input" type="text" placeholder="Booking No" />
                </div>
                <div className="smd-w-100">
                    <label htmlFor="">By Client Name</label><br />
                    <input className="search-input" type="text" placeholder="Client Name" />
                </div> */}
                <PortDropDown label="From" value={fields.fromPort} data={port} name="fromPort" handleChange={handleChange} />
                <PortDropDown label="To" value={fields.toPort} data={port} name="toPort" handleChange={handleChange} />
                <div className="smd-w-100">
                    <label htmlFor="">Departure Date</label><br />
                    <input className="search-input" name="fromDate" type="date" onChange={handleChange} />
                </div>
                {/* <div className="smd-w-100">
                    <label htmlFor="">Return Date</label><br />
                    <input className="search-input" name="toPort" type="date" onChange={handleChange} />
                </div> */}
                <div>
                    <button onClick={handleSubmit} className="pointer btn">Search</button>
                </div>
            </div>}
        </div>
    )
}

function PortDropDown({ value, label, data, name, handleChange }) {
    return (
        <div className="smd-w-100">
            <label htmlFor="from" className="search-label sm-fs-12px">{label}</label><br />
            <select value={value} onChange={handleChange} className="md-m-t search-input" name={name}>
                {data.length && data.map((port, index) => <option key={index} value={port.portId}>{port.portName}</option>)}
            </select>
        </div>
    )
}