import Loader from '../icons/Loader';
import { useLocation } from 'react-router-dom';
import { getChangeDateVoyages } from '../../api/search';
import { useContext, useEffect, useState } from 'react';
import Msg from '../msg/Msg';
import OneWay from '../common/OneWay';
import RoundTrip from '../common/RoundTrip';
import { ChangeDatecontext } from '../ProcessContext';
export default function ChangeDateVoyageList() {
    const value = useContext(ChangeDatecontext);
    const { state } = useLocation();
    const [voyages, setVoyages] = useState([]);
    const [voyagesReturn, setVoyagesReturn] = useState([]);
    const [luggageInfo, setLuggageInfo] = useState('');
    const [showReturn, setShowReturn] = useState(false);
    const [loader, setLoader] = useState(true);
    const [fromTo, setFromTo] = useState({
        from: '',
        to: ''
    })
    const [book, setBook] = useState({
        bookingId:state.fields.bookingId,
        voyageId: "",
        returnVoyageId: "",
        noOfPax: state.fields.noOfPax,
        classType: state.fields.classType,
        bookFor: state.fields.bookFor,
        oneWay: false,
        round: false,
        isReturnDate: state.fields.isReturnDate
    })
    // state.fields.currency = localStorage.getItem('chargeCurrency') ? localStorage.getItem('chargeCurrency') : "";
    useEffect(() => {
        getChangeDateVoyages(state.fields).then(
            data => {
                if (data) {
                    value.setTravelDate(data.travelDate);
                    value.setReturnTravelDate(data.returnTravelDate)
                    const collectDate = {}
                    if (data.matchingVoyages.length > 0) {
                        setVoyages(data.matchingVoyages);
                        setFromTo({
                            from: data.matchingVoyages[0].voyageFromName,
                            to: data.matchingVoyages[0].voyageToName
                        })

                        collectDate.dateOfTravel = data.matchingVoyages[0].dateOfTravel;
                        collectDate.voyageFrom = data.matchingVoyages[0].voyageFrom;
                        collectDate.voyageTo = data.matchingVoyages[0].voyageTo;

                    }
                    if (data.returnMatchingVoyages.length > 0) {
                        setVoyagesReturn(data.returnMatchingVoyages)
                        collectDate.returnDateOfTravel = data.returnMatchingVoyages[0].dateOfTravel;
                        collectDate.returnVoyageFrom = data.returnMatchingVoyages[0].voyageFrom;
                        collectDate.returnVoyageTo = data.returnMatchingVoyages[0].voyageTo;

                    }
                    if (data.luggageInfo) setLuggageInfo(data.luggageInfo)
                    value.setDate({ ...value.date, ...collectDate })
                    setLoader(false)
                }
                else setLoader(false)
            }
        )
    }, [])

    return (
        <div>
            <div className='sm-p-1'>
                {voyagesReturn.length > 0 && <div className='d-f c-gap-1 sm-float-n sm-f-j-c' style={{ float: 'right' }}>
                    <input id='round-trip' type="checkbox" onChange={() => setShowReturn(!showReturn)} style={{ width: '25px', height: '25px' }} checked={showReturn} />
                    <label htmlFor="round-trip">Return</label>
                </div>}
                {voyages.length > 0 && <p className='mb-1 b-500 sm-txt-c mt-2'>Select your voyage from <span className='primary b-600'>{showReturn ? fromTo.to : fromTo.from}</span> to <span className='primary b-600'>{showReturn ? fromTo.from : fromTo.to}</span></p>
                }                <p className='sm-txt-c'>
                    {/* {
                    showReturn ? voyagesReturn.length ? voyagesReturn.length == 1 ? "1 voyage" : voyagesReturn.length + " voyages"
                        : voyages.length ? voyages.length == 1 ? "1 voyage" : voyages.length + "voyages"
            } */}
                    {showReturn && (voyagesReturn.length ? voyagesReturn.length == 1 ? "1 Voyage Found!" : voyagesReturn.length + " Voyages Found!" : "")}
                    {!showReturn && (voyages.length ? voyages.length == 1 ? "1 Voyage Found!" : voyages.length + " Voyages Found!" : "")}
                </p>
            </div>
            <div className='mt-2 d-f f-col f-a-c r-gap-2 sm-p-1'>
                {showReturn ? <RoundTrip luggageInfo={luggageInfo} voyages={voyagesReturn} book={book} setBook={setBook} type={state.fields.type} setShowReturn={setShowReturn} /> : <OneWay luggageInfo={luggageInfo} book={book} setBook={setBook} type={state.fields.type} voyages={voyages} setShowReturn={setShowReturn} />}
                {!voyages.length != loader && <Msg />}
            </div>
            {loader && <div className='overlay'><Loader color='white' /></div>}
        </div>
    )
}