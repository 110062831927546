import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import calender from '../../assets/images/calender.png'
export default function ChangeDate() {
    const navigate = useNavigate();
    const { state } = useLocation()
    const minDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')
    const [fields, setFields] = useState({
        cancelStatus:state.cancelStatus,
        changeStatusOneway:state.changeStatusOneway,
        changeStatusReturnway:state.changeStatusReturnway,
        bookingId:state.bookingId,
        type: state.type,
        bookFor: state.bookFor,
        fromPort: state.fromPort,
        toPort: state.toPort,
        depatureDate: state.depatureDate,
        returnDate: state.returnDate ? state.returnDate : state.oldReturnDate,
        classType: state.classId ? state.classId : state.classType,
        noOfPax: state.noOfPax,
        currency: localStorage.getItem('chargeCurrency'),
        oldDepatureDate: state.oldDepatureDate ? state.oldDepatureDate : state.depatureDate,
        oldReturnDate: state.oldReturnDate ? state.oldReturnDate : state.returnDate,
        isReturnDate: false,
        travelType: state.travelType
    });
    function handleChange(e) {
        let value = e.target.value;
        if (e.target.type == "date") value = new Date(value).toLocaleDateString('en-GB');
        setFields({ ...fields, [e.target.name]: value })
    }
    function handleSumbit() {
        if (fields.type == 'two') {
            if (fields.oldDepatureDate == fields.depatureDate) {
                fields.depatureDate = fields.depatureDate;
                // fields.fromPort = state.toPort;
                // fields.toPort = state.fromPort;
                fields.fromPort = state.fromPort;
                fields.toPort = state.toPort;
                // fields.type = "one"
                fields.isReturnDate = true
            }
            if (fields.oldReturnDate == fields.returnDate) {
                // fields.returnDate = ""
                // fields.type = 'one'
            }
        }
        navigate('/change-date-page', { state: { fields } });  

    }
    return (
        <div className="p-c p-2 d-f f-j-c f-a-c sm-p-1" style={{ width: "fit-content", height: "fit-content" }}>
            <div className="fade p-r d-f br-r-1 bg-white" style={{ maxWidth: "416px", height: state.type == 'two' ? "424px" : '370px', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="p-1-5 d-f f-col f-spb">
                    {/* <img src={questionImg} alt="" style={{ maxWidth: "fit-content", width: '100%' }} /> */}
                    <div>
                        <h1 className="b-500 sm-txt-a-c">Adjust your Voyage Date!</h1>
                        <p className="sm-txt-a-c gray mt-1">Provide your new preferred dates,
                            and we'll ensure a smooth transition!</p>
                    </div>
                    <div>
                        <div>
                            <label htmlFor="">Departure</label><br />
                            <div className="p-r">
                                <input className="search-input pax-drop-bg" type="text" style={{opacity:state.changeStatusOneway?1:.5, width: '100%', paddingRight: '8px', backgroundImage: `url(${calender})` }} value={fields.depatureDate} readOnly />
                                <input name="depatureDate" disabled={!state.changeStatusOneway} min={minDate} style={{ opacity: 0, border: 'none', color: "var(--primaryColor)", backgroundColor: 'none', width: '30px', height: '30px', top: 0, bottom: 0, margin: "auto", position: 'absolute', right: 0, }} type="date" value={fields.depatureDate.split('/').reverse().join('-')} onChange={(e)=>{if(state.changeStatusOneway)handleChange(e)}} />
                            </div>
                            {/* <input className="search-input w-100" name="depatureDate" min={minDate} type="date" value={fields.depatureDate.split('/').reverse().join('-')} onChange={handleChange} /> */}
                        </div>
                        {state.travelType == 'two' && <div className="mt-1">
                            <label htmlFor="">Return</label><br />
                            <div className="p-r">
                                <input className="search-input pax-drop-bg" type="text" style={{ width: '100%', paddingRight: '8px', backgroundImage: `url(${calender})` }} value={fields.returnDate} readOnly />
                                <input name="returnDate" min={minDate} style={{ opacity: 0, border: 'none', color: "var(--primaryColor)", backgroundColor: 'none', width: '30px', height: '30px', top: 0, bottom: 0, margin: "auto", position: 'absolute', right: 0, }} type="date" value={fields.returnDate.split('/').reverse().join('-')} onChange={handleChange} />
                            </div>
                        </div>}
                    </div>
                    <div className="d-f c-gap-2 f-j-c f-wrap sm-r-gap-1 w-100">
                        <button className="br-btn pointer" onClick={() => navigate('/dashboard')} style={{ width: "168px" }}>Cancel</button>
                        <button className="btn pointer" disabled={state.type == 'one' ? fields.depatureDate == fields.oldDepatureDate ? true : false : ((fields.depatureDate == fields.oldDepatureDate) && (fields.returnDate == fields.oldReturnDate)) ? true : false} onClick={handleSumbit} style={{ opacity: state.type == 'one' ? fields.depatureDate == fields.oldDepatureDate ? .4 : 1 : ((fields.depatureDate == fields.oldDepatureDate) && (fields.returnDate == fields.oldReturnDate)) ? .4 : 1, width: "168px" }}>Change Date</button>
                    </div>
                </div>
            </div>
        </div >
    )
}