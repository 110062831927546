import img from '../../assets/images/loaderIcon.png'
export default function Loader({ color = "#f3f3f3" }) {
    const style = {
        width: '35px',
        height: "35px",
        border: `4px dotted ${color}`,
        borderRadius: '50%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        animation: 'spin 2s linear infinite',
        // backgroundImage: `url(${img})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
    }

    return (
        <div style={style}></div>
    )
}