import { useState } from "react";
import DateTimeCard from "../common/DateTimeCard";
import { BookBtn } from "../btn";
import ViewDetails from "../common/ViewDetails";
export default function YourVoyages({ book, maxLuggage }) {
    const [view, setView] = useState(false)
    const style = {
        container: {
            width: '596px',
            borderRadius: '16px',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
            padding: '0 1.5rem',
            position: 'relative',
        },
        codeBatch: {
            width: "95px",
            height: "24px",
            backgroundColor: "var(--primaryColor)",
            borderRadius: '0 0 4px 4px',
            color: 'white',
            fontWeight: 500,
            textAlign: "center"
        },
        amountBatch: {
            width: "112px",
            backgroundColor: "#D9D9D9",
            display: 'flex',
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        seatBatch: {
            width: 'fit-content',
            backgroundColor: "#ec9e39",
            position: 'absolute',
            top: 0,
            right: '3rem'
        }
    }
    return (
        <div style={style.container}>
            <div className="d-f f-spb" style={{ height: '158px', }}>
                <div className="d-f f-col">
                    <div className="d-f f-col f-spb">
                        <div className="mb-1 fs-14px" style={style.codeBatch}>{book.voyageId}</div>
                        <DateTimeCard fs={{ date: 'fs-14px', time: 'fs-1-5' }} data={book} />
                        {/* <span style={{ color: "#2500BA", textDecoration: 'underline', cursor: "pointer" }} onClick={() => setView(!view)}>{view ? 'Close details' : 'View details'}</span> */}
                    </div>
                    <div className="d-f f-spb">
                        <span style={{ color: "var(--primaryColor)", textDecoration: 'underline', cursor: "pointer" }} onClick={() => setView(!view)}>{view ? 'Close details' : 'View details'}</span>
                        <div className="d-f f-spb">
                            <span>Class:</span><span className="b-700">&nbsp;{book.className}</span>
                        </div>
                    </div>
                </div>
                <div style={style.amountBatch}>
                    <span>{book.chargeCurrency}</span>
                    <span className="fs-1-5 bold-500">{book.totalChargeAmount.toLocaleString()}</span>
                </div>
            </div>
            {view && <ViewDetails maxLuggage={maxLuggage} />}
        </div>
    )
}