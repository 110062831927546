import fail from '../../assets/images/fail.png';
import success from '../../assets/images/success.png';
import { useNavigate } from "react-router-dom"
export default function Msg() {
    return (
        <div className='txt-a-c'>
        <img src={fail} alt="" />
        <p className='b-500' style={{ fontSize: 'larger', marginBottom: '15px' , marginTop: '20px' }}>No seats Available or Voyages not scheduled in the date you selected!</p>
        <p className='grey' style={{ marginBottom: '10px' }}>Please modify the date and search again...</p>
    </div>
    
    )
}

export function login() {
    return (
        <div>
            <img src={fail} alt="" />
            <p className='b-500'>You have logged in successfully!</p>
            <p className='grey'>Continuing to your Dashboard....</p>
        </div>
    )
}

export function register() {
    return (
        <div>
            <img src={fail} alt="" />
            <p className='b-500'>You have Registered in successfully!</p>
            <p className='grey'>Continuing to your Dashboard....</p>
        </div>
    )
}
export function Payment() {
    const navigate = useNavigate();
    const style = {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
        height: '100%',
        backgroundColor: 'rgb(0,0,0,.4)'
    }
    return (
        <div style={style}>
            <div className="p-c br-r-8 txt-a-c p-2" style={{ width: 'fit-content', height: 'fit-content', background: 'white' }}>
                <img src={success} alt="" width="100px" />
                <p className='b-500'>You have made the payment in successfully!</p>
                <p className='grey'>Continuing to Fill Passenger Details...</p>
                <button className='btn mt-1 pointer' onClick={() => navigate('/')}>ok</button>
            </div>
        </div>
    )
}
export function Booking() {
    return (
        <div>
            <img src={fail} alt="" />
            <p className='b-500'>Your Ticket(s) has been booked successfully!</p>
            <p className='grey'>Check your mail box for the ticket(s) or Download here.</p>
            <div>
                <button>Download Ticket(s)</button>
                <button>Back to Dashboard</button>
            </div>
        </div>
    )
}