import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from "react-router-dom";
import YourVoyages from "../desk/YourVoyages";
import MobYourVoyages from '../mobile/YourVoyages';
import TabYourVoyages from '../tab/YourVoyages';
import Loader from '../icons/Loader';
import Selection from "../desk/Selection";
import { booking, reserve } from '../../api/book';
import { useEffect, useState, useContext } from 'react';
import { ProcessContext, Roundcontext } from '../ProcessContext';
import Payment from './Payment';
import PassengerDts from './PassengerDts';
import LuggageSelection from '../common/LuggageSelection';
import MealSelection from '../common/MealSelection';
import SeatSelection from '../common/SeatSelection';
import { getOrderId, verifyPayment } from '../../api/payment';
import logo from '../../assets/images/logo.png';
import successImg from '../../assets/images/success.png';
import failImg from '../../assets/images/fail.png';
import warningImg from '../../assets/images/warning.png';
import razorPayImg from '../../assets/images/razorpay.svg';
import hsbcImg from '../../assets/images/hsbc.svg';
import ccAvenuePayImg from '../../assets/images/cc-avenue.svg';
import { cancelReserving, getCreditLimit, creditReserve } from '../../api/api';
import QRCode from "react-qr-code";
export default function FareSummary() {
    const value = useContext(ProcessContext);
    const roundContext = useContext(Roundcontext)
    const navigate = useNavigate();
    const { state } = useLocation();
    const [msg, setMsg] = useState({
        status: "success",
        message: '',
        alertMessage: ''
    });
    const [loader, setLoader] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [confirm, setConfirm] = useState(localStorage.getItem('bookingId') ? true : false);
    const [bookingId, setBookingId] = useState('');
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paxSuccess, setPaxSuccess] = useState(false);
    const [proceedPaymentDts, setProceedPaymentDts] = useState({
        bookingId: localStorage.getItem('bookingId') ? JSON.parse(localStorage.getItem('bookingId')) : [],
        food: [],
        returnFood: [],
        weight: 0,
        returnWeight: 0,
        paymentAmount: 0
    })
    const [creditMsg, setCreditMsg] = useState(false)
    const [creditLimit, setCreditLimit] = useState(0);
    const [isCredit, setIsCredit] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState(false);
    const [qrCodeModal, setQrCodeModal] = useState(false)
    const [qrCode, setQrCode] = useState({
        url: "",
        amount: 0
    });
    const [otherMsg, setOtherMsg] = useState(false);
    let book = "";
    let travelDate = "";
    if (state) {
        book = state.book
        travelDate = state.travelDate.split('-').reverse().join('/');
    }
    else {
        book = JSON.parse(localStorage.getItem('book'));
        travelDate = localStorage.getItem('travelDate');
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getCreditLimit({ bookingId: proceedPaymentDts.bookingId }).then(data => {
                if (data) {
                    setCreditLimit(data.creditLimit)
                    setIsCredit(data.isCredit)
                }
            })
        }
        if (value.process.processSecond) {
            value.process.processSecond.style.color = "white";
            value.process.processSecond.style.backgroundColor = '#1F3167';
        }

        if (state) {
            value.setTravelDate(state.travelDate)
            value.setReturnTravelDate(state.returnTravelDate)
        }
        // value.setShowSearchBtn(false);
        setProceedPaymentDts({
            ...proceedPaymentDts,
            bookingId: book.bookingId ? book.bookingId : JSON.parse(localStorage.getItem('bookingId')),
            paymentAmount: book.oneWayData.totalChargeAmount + (book.roundData ? book.roundData.totalChargeAmount : 0),

        })
        if (book.isReservation) setConfirm(true);
        value.setDate({
            dateOfTravel: book.oneWayData.dateOfTravel,
            voyageFrom: book.oneWayData.voyageFrom,
            voyageTo: book.oneWayData.voyageTo,
            returnDateOfTravel: book.roundData ? book.roundData.dateOfTravel : "",
            returnVoyageFrom: book.roundData ? book.roundData.voyageFrom : "",
            returnVoyageTo: book.roundData ? book.roundData.voyageTo : ""
        })
    }, [])
    const isMob = useMediaQuery({ query: '(max-width:768px)' })
    const isTab = useMediaQuery({ minWidth: 769, maxWidth: 1279 })
    const isDesk = useMediaQuery({ query: '(min-width: 1280px)' })

    const reserveData = {
        type: book.type,
        fareCurrency: "",
        bookFor: book.bookFor,
        classType: book.classType,
        noOfPax: book.noOfPax,

        voyageId: book.voyageId,
        ferryId: book.ferryId,
        voyageYear: book.voyageYear,
        fareAmount: book.oneWayData.fareAmount,
        taxAmountInFareCurrency: book.oneWayData.taxAmountInFareCurrency,
        totalChargeAmount: book.oneWayData.totalChargeAmount,
        totalFareAmount: book.oneWayData.totalFareAmount,
        adultPrice: book.oneWayData.adultPrice,
        childPrice: book.oneWayData.childPrice,
        infantPrice: book.oneWayData.infantPrice,

        returnVoyageId: book.type == 'two' ? book.returnVoyageId : '',
        returnVoyageYear: book.type == 'two' ? book.returnVoyageYear : 0,
        returnFerryId: book.type == 'two' ? book.returnFerryId : '',
        returnFareAmount: book.type == 'two' ? book.roundData.fareAmount : 0,
        returnTaxAmountInFareCurrency: book.type == 'two' ? book.roundData.taxAmountInFareCurrency : 0,
        returntotalFareAmount: book.type == 'two' ? book.roundData.totalFareAmount : 0,
        returntotalChargeAmount: book.type == 'two' ? book.roundData.totalChargeAmount : 0,
        returnAdultPrice: book.type == 'two' ? book.roundData.adultPrice : 0,
        returnChildPrice: book.type == 'two' ? book.roundData.childPrice : 0,
        returnInfantPrice: book.type == 'two' ? book.roundData.infantPrice : 0
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    // async function diplayRazorpay(orderId) {
    //     const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    //     console.log(res);

    //     if (!res) {
    //         alert("Razorpay sdk failed to load. Are you online ?");
    //     }

    //     const dev = (document.domain = "localhost");

    //     const options = {
    //         key: dev ? "rzp_test_jBcbhugBiZ4Bn3" : "PASTE_THE_ACTUAL_KEY", // Enter the Key ID generated from the Dashboard
    //         amount: "400000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //         currency: "INR",
    //         name: "Sail IndSri", //your business name
    //         description: "Test Transaction",
    //         image: logo,
    //         order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //         handler: function (response) {
    //             // alert(response.razorpay_payment_id);
    //             // alert(response.razorpay_order_id);
    //             // alert(response.razorpay_signature);
    //             alert('new hi success');
    //             verifyPayment({
    //                 razorpay_payment_id: response.razorpay_payment_id,
    //                 razorpay_order_id: response.razorpay_order_id,
    //                 razorpay_signature: response.razorpay_signature,
    //                 bookingId: proceedPaymentDts.bookingId
    //             }).then(data => {
    //                 if (data) {
    //                     // setPaymentSuccess(true);
    //                     alert('hi success');
    //                     console.log('hi successfuly payment');
    //                     setTimeout(() => navigate('/invoice', { state: proceedPaymentDts.bookingId }), 3000)

    //                 }
    //             })

    //         },
    //         redirect: true,
    //         callback_url: `${process.env.REACT_APP_DOAMIN}/payment/PaymentVerification?cCode=${proceedPaymentDts.bookingId[0]}&dCode=${proceedPaymentDts.bookingId[1]}&year=${proceedPaymentDts.bookingId[2]}&sNo=${proceedPaymentDts.bookingId[3]}`,
    //         prefill: {
    //             //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
    //             name: localStorage.getItem('username'), //your customer's name
    //             email: localStorage.getItem('email'),
    //             contact: localStorage.getItem('phoneNumber'), //Provide the customer's phone number for better conversion rates
    //         },
    //     };

    //     const paymentObject = new window.Razorpay(options);
    //     paymentObject.open();
    //     setLoader(false)
    // }
    function SLAgentProceedPayment() {
        async function goTopayment() {
            setLoader(true)
            try {
                const response = await fetch(process.env.REACT_APP_DOAMIN + '/ProceedPayment/CreditPayment', {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json",
                        "Authorization": `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(proceedPaymentDts)
                });

                const result = await response.json();
                console.log(result);
                if (response.status == 200) {
                    localStorage.setItem('bookingId', JSON.stringify(proceedPaymentDts.bookingId));
                    // if (result.orderId) diplayRazorpay(result.orderId)
                    navigate('/invoice', { state: proceedPaymentDts.bookingId })
                }
                else {
                    throw result
                }
            } catch (err) {
                setMsg({
                    status: 'failed',
                    message: err.message,
                    alertMessage: ""
                })
            }
            // getOrderId(proceedPaymentDts).then(
            //     data => {
            //         localStorage.setItem('bookingId', JSON.stringify(proceedPaymentDts.bookingId));
            //         console.log(data)
            //         if (data.orderId) diplayRazorpay(data.orderId)
            //         else navigate('/seat-selection', { state: bookingId })
            //     }
            // )
        }
        if (proceedPaymentDts.paymentAmount > creditLimit) {
            // setMsg({
            //     status: 'failed',
            //     message: "Your credit is insufficient",
            //     alertMessage: "Topup the money and try again"
            // })
            setCreditMsg(true)
            // setTimeout(() => window.location.assign('/dashboard'), 3000)
        } else goTopayment() //store all precedures in backend 
        // navigate('/seat-selection', { state: proceedPaymentDts.bookingId })

    }
    function handleConfirm() {
        setLoader(true)
        reserveData.bookingId = [];
        if (!localStorage.getItem('token')) {
            localStorage.setItem('book', JSON.stringify(book));
            localStorage.setItem('travelDate', state.travelDate.split('-').reverse().join('/'))
            window.location.assign('/')
        } else {
            // const currency = localStorage.getItem('chargeCurrency')
            // const role = localStorage.getItem('role');
            // if (currency != 'INR' && role == 1) {
            //     setLoader(false);
            //     setOtherMsg(true)
            // } 
            booking(reserveData).then(data => {
                if (data) {
                    setLoader(false);
                    setCreditLimit(data.creditLimit);
                    setIsCredit(data.isCredit)
                    setMsg({
                        status: 'success',
                        message: data.message,
                        alertMessage: data.alertMessage
                    })
                    setProceedPaymentDts({ ...proceedPaymentDts, bookingId: data.bookingId });
                    localStorage.setItem('bookingId', JSON.stringify(data.bookingId));
                    setTimeout(() => {
                        setConfirm(true)
                        setMsg({ status: "success", message: "", alertMessage: "" });
                    }, 5000)
                }

            })

        }
    }
    function handleReserve() {
        setLoader(true)
        reserve(reserveData).then(data => {
            if (data) {
                setLoader(false)
                setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage })
                setTimeout(() => {
                    setMsg({ msg: 'success', message: "", alertMessage: "" });
                    setLoader(false)
                    navigate('/dashboard')
                }, 3000);
            } else {
                setLoader(false)
                setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" })
                setTimeout(() => {
                    setMsg({ status: "fail", message: "", alertMessage: "" });
                    setLoader(false)
                    navigate('/dashboard')
                }, 3000);
            }

        });
    }
    function handleCreditReserve() {
        setLoader(true)
        creditReserve({ bookingId: proceedPaymentDts.bookingId }).then(data => {
            if (data) {
                setLoader(false)
                setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage })
                console.log(data)
                setTimeout(() => {
                    setMsg({ msg: 'success', message: "", alertMessage: "" });
                    setLoader(false)
                    navigate('/dashboard')
                }, 3000);
            } else {
                setLoader(false)
                setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" })
                console.log(data)
                setTimeout(() => {
                    setMsg({ status: "fail", message: "", alertMessage: "" });
                    setLoader(false)
                    navigate('/dashboard')
                }, 3000);
            }

        });
    }
    function handleCancel() {
        setLoader(true)
        cancelReserving({ bookingId: proceedPaymentDts.bookingId })
            .then(data => {
                setLoader(false)
                setMsg({ status: 'success', message: data.message, alertMessage: "" });
                setTimeout(() => navigate('/dashboard'), 3000)
                localStorage.removeItem('bookingId')
            })
    }
    function showPaymentOptions() {
        if (isCredit) SLAgentProceedPayment()
        else setPaymentOptions(true)
    }
    const style = {
        totalBatch: {
            width: "fit-content",
            height: "68px",
            backgroundColor: "#D9D9D9",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '1rem',
            borderRadius: "12px"
        }
    }
    return (
        <div className='p-1'>
            <div className='p-1 sm-m-a' style={style.totalBatch}>
                <span>Total Fare</span>
                {/* <span>{book.oneWayData.chargeCurrency}</span> */}
                <span className="fs-1-5 b-600" style={{ fontFamily: "Arial, sans-serif" }}>{proceedPaymentDts.paymentAmount.toLocaleString('en-GB', { style: 'currency', currency: book.oneWayData.chargeCurrency })}</span>
            </div>
            <p className='mtb-1-5 fs-20px b-500 sm-txt-c'>Your voyages</p>
            <div className='d-f f-col f-a-c r-gap-1-5'>
                {isMob && <MobYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
                {isTab && <TabYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
                {isDesk && <YourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}

                {book.round && isMob && <MobYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
                {(book.round && isTab) && <TabYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
                {book.round && isDesk && <YourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}

            </div>
            {confirm && <div className='m-a mt-2' style={{ maxWidth: '1080px' }}><PassengerDts type={book.type} toPort={book.oneWayData.voyageTo} bookingId={JSON.parse(localStorage.getItem('bookingId'))} setPaxSuccess={setPaxSuccess} /></div>}
            {confirm && <div className="mt-2 r-gap-2 d-f f-wrap f-spb m-a" style={{ maxWidth: '1080px' }}>
                {/* <SeatSelection /> */}
                <LuggageSelection data={book} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
                <Roundcontext.Provider value={book.round}>
                    <MealSelection data={book} travelDate={travelDate} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
                </Roundcontext.Provider>
            </div>}
            <div className="mt-2 d-f f-j-e">
                {/* <div className="d-f f-col">
                    <p className="fs-20px b-500">Total Fare</p>
                    <p className="fs-1-5 b-600">{book.totalFare}</p>
                    <p>Overall Cost for All Passengers (Inclusive of Taxes and Fees)</p>
                    <Link className="black">Fare Details</Link>
                    <Link className="black">Payment Policy</Link>
                    <Link className="black">Conditions of Travel</Link>
                </div> */}
                {!confirm && <div className='d-f sm-f-col sm-m-a c-gap-2 r-gap-1'>
                    <button onClick={() => navigate('/home')} className="pointer br-btn" style={{ width: '256px', height: '48px' }}>Cancel</button>
                    {(localStorage.getItem('role') == "2" || localStorage.getItem('role') == "3")
                        && <button onClick={handleReserve} className="pointer br-btn primary" style={{ width: '256px', height: '48px', opacity: book.isReservation ? '.4' : 1 }} disabled={book.isReservation ? true : false}>Reserve</button>}
                    <button onClick={handleConfirm} className="pointer btn" style={{ width: '256px', height: '48px' }}>Confirm Voyages</button>
                </div>}
                {confirm && <div className='d-f c-gap-2 sm-f-col sm-m-a sm-r-gap-1'>
                    <button onClick={handleCancel} className="pointer br-btn primary" style={{ width: '256px', height: '48px' }}>Cancel</button>
                    <button onClick={showPaymentOptions} className="pointer btn" style={{ width: '256px', height: '48px', opacity: !paxSuccess ? .4 : 1 }} disabled={!paxSuccess}>Proceed to Payment</button>
                </div>}
            </div>
            {msg.message && <Success data={msg} setMsg={setMsg} />}
            {creditMsg && <CreditMsg setCreditMsg={setCreditMsg} handleCreditReserve={handleCreditReserve} />}
            {paymentSuccess && <PaymentSuccess bookingId={proceedPaymentDts.bookingId} />}
            {loader && <div className='overlay'>
                <Loader />
            </div>}
            {/* {
                showPayment && <div className='overlay'><Payment proceedPayment={!proceedPayment} /></div>
            } */}
            {paymentOptions && <PaymentOptions setQrCodeModal={setQrCodeModal} setQrCode={setQrCode} setMsg={setMsg} setLoader={setLoader} proceedPaymentDts={proceedPaymentDts} setPaymentOptions={setPaymentOptions} />}
            {qrCodeModal && <QRcodeModal qrCode={qrCode} setQrCodeModal={setQrCodeModal} />}
            {otherMsg && <OtherMsg />}
        </div>
    )
}

function Success({ data, setMsg }) {
    return (
        <div className='overlay'>
            <div className='p-c p-1 scale' style={{ width: 'fit-content', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c mt-1'>
                        <p className='primary b-500'>{data.message}</p>
                        <p className='gray' style={{ marginTop: ".5rem" }}>{data.alertMessage}</p>
                        {data.status == "failed" && <button className='pointer msg-btn mt-1' onClick={() => window.location.assign('/dashboard')}>ok</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
function CreditMsg({ setCreditMsg, handleCreditReserve }) {
    const navigate = useNavigate()
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ maxWidth: '450px', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={warningImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c'>
                        <p className='primary'>Your credit is insufficient</p>
                        <p>Do you want to reserve?</p>
                        {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
                        <div className="d-f f-j-c c-gap-1 mt-2 pb-1">
                            <button onClick={() => { setCreditMsg(false); navigate('/dashboard') }} className="btn pointer" style={{ width: '90px', height: "34px" }}>Cancel</button>
                            <button onClick={() => { setCreditMsg(false); handleCreditReserve() }} className="br-btn primary pointer" style={{ width: '90px', height: "34px" }}>Reserve</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function PaymentSuccess({ bookingId }) {
    const navigate = useNavigate()
    return (
        <div className='overlay'>
            <div className='sm-malr-1 p-c p-1 br-r-8 bg-white txt-a-c' style={{ width: '300px', height: "fit-content" }}>
                <p className='primary'>Payment Successfull</p>
                <button className='pointer msg-btn mt-1' onClick={() => navigate('/seat-selection', { state: bookingId })}>ok</button>
            </div>
        </div>
    )
}
function PaymentOptions({ setQrCode, setMsg, setLoader, proceedPaymentDts, setPaymentOptions, setQrCodeModal }) {

    // window.location.href = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=44d1b861f54c64b7f2d24cea9540f1b15b46609f979be9ee4a424d9c602e01cf6958b9a27533cc0bcf18b357808e7ab9781844d77baec8d8b556d462c960439cbc870e95093a8d4a56828c718db59f42aa962b16780fba48ab34146dd9b597785e07f46499cfdb9c8238caafeed02f3610a06994f15641cef90696d73080256c5c4b1e9f7ca9ceece2cd53f40390a494606543bea76e969c4dcb09fafcbabc316c94fefee73426c875a6aef537eece9e3ba9f479ce295c981c6c326692bcf2e7b9d6c83e39a951f07fc1751488c17d89a49b4fc2133f2f7ba811aad40954f245f61a044361c0f202b5f54a35cd7a6ff49e00c1800d95ebe4d58907211d21e616dc98bd28603b5dc7f17680507be496cf9395d2ac733694deb764a9a96b2fe39c95d536b4a138b5d51dfcc9083e432e4713f5e8c9204572488315682079d90d8d&access_code=AVUM67LC95AH37MUHA";
    // window.location.replace('https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=44d1b861f54c64b7f2d24cea9540f1b15b46609f979be9ee4a424d9c602e01cf6958b9a27533cc0bcf18b357808e7ab9781844d77baec8d8b556d462c960439cbc870e95093a8d4a56828c718db59f42aa962b16780fba48ab34146dd9b597785e07f46499cfdb9c8238caafeed02f3610a06994f15641cef90696d73080256c5c4b1e9f7ca9ceece2cd53f40390a494606543bea76e969c4dcb09fafcbabc316c94fefee73426c875a6aef537eece9e3ba9f479ce295c981c6c326692bcf2e7b9d6c83e39a951f07fc1751488c17d89a49b4fc2133f2f7ba811aad40954f245f61a044361c0f202b5f54a35cd7a6ff49e00c1800d95ebe4d58907211d21e616dc98bd28603b5dc7f17680507be496cf9395d2ac733694deb764a9a96b2fe39c95d536b4a138b5d51dfcc9083e432e4713f5e8c9204572488315682079d90d8d&access_code=AVUM67LC95AH37MUHA')
    const navigate = useNavigate();
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function diplayRazorpay(orderId) {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");


        if (!res) {
            alert("Razorpay sdk failed to load. Are you online ?");
        }

        const dev = (document.domain = "sailindsri.com");

        const options = {
            key: dev ? "rzp_live_A3vQFmno5z73g7" : "rzp_live_A3vQFmno5z73g7", // Enter the Key ID generated from the Dashboard
            // rzp_live_A3vQFmno5z73g7
            name: "Sail IndSri", //your business name
            description: "Ticket Booking - Sail IndSri",
            image: logo,
            order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                verifyPayment({
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                    bookingId: proceedPaymentDts.bookingId
                }).then(data => {
                    if (data) {
                        // setPaymentSuccess(true);

                        setTimeout(() => navigate('/invoice', { state: proceedPaymentDts.bookingId }), 3000)

                    }
                })

            },
            redirect: true,
            callback_url: `${process.env.REACT_APP_DOAMIN}/payment/PaymentVerification?cCode=${proceedPaymentDts.bookingId[0]}&dCode=${proceedPaymentDts.bookingId[1]}&year=${proceedPaymentDts.bookingId[2]}&sNo=${proceedPaymentDts.bookingId[3]}`,
            prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: localStorage.getItem('username'), //your customer's name
                email: localStorage.getItem('email'),
                contact: localStorage.getItem('phoneNumber'), //Provide the customer's phone number for better conversion rates
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoader(false)
    }
    async function razorPrceedPayment() {
        setLoader(true)
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/ProceedPayment/razorPayment', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(proceedPaymentDts)
            });

            const result = await response.json();
            if (response.status == 200) {
                localStorage.setItem('bookingId', JSON.stringify(proceedPaymentDts.bookingId));
                if (result.orderId) diplayRazorpay(result.orderId)
                else navigate('/invoice', { state: proceedPaymentDts.bookingId })
            }
            else {
                throw result
            }
        } catch (err) {
            setLoader(false)
            setMsg({
                status: 'Payment Failed',
                message: err.message,
                alertMessage: err.alertMessage
            })
        }
    }
    async function ccAvenueProceedPayment() {
        // const url = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=44d1b861f54c64b7f2d24cea9540f1b15b46609f979be9ee4a424d9c602e01cf6958b9a27533cc0bcf18b357808e7ab9781844d77baec8d8b556d462c960439cbc870e95093a8d4a56828c718db59f42aa962b16780fba48ab34146dd9b597785e07f46499cfdb9c8238caafeed02f3610a06994f15641cef90696d73080256c5c4b1e9f7ca9ceece2cd53f40390a494606543bea76e969c4dcb09fafcbabc316c94fefee73426c875a6aef537eece9e3ba9f479ce295c981c6c326692bcf2e7b9d6c83e39a951f07fc1751488c17d89a49b4fc2133f2f7ba811aad40954f245f61a044361c0f202b5f54a35cd7a6ff49e00c1800d95ebe4d58907211d21e616dc98bd28603b5dc7f17680507be496cf9395d2ac733694deb764a9a96b2fe39c95d536b4a138b5d51dfcc9083e432e4713f5e8c9204572488315682079d90d8d&access_code=AVUM67LC95AH37MUHA';
        // window.open(url, '_blank');
        setLoader(true)
        setPaymentOptions(false)
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/ProceedPayment/CCAvenuePayment', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(proceedPaymentDts)
            });
            const result = await response.json();
            if (response.status == 200) {
                window.open(result.returnUrl, '_self', 'noopener,noreferrer')
            } else throw result
        } catch (err) {
            setLoader(false)
            setMsg({
                status: 'failed',
                message: err.message,
                alertMessage: err.alertMessage
            })
        }
    }
    async function upiProceedPayment() {
        setLoader(true);
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/ProceedPayment/HSBCPayment', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(proceedPaymentDts)
            });
            const result = await response.json();
            if (response.status == 200) {
                setLoader(false);
                setQrCode({ url: result.qrCodeUrl, amount: result.amount });
                setQrCodeModal(true)
            }
            throw result
        } catch (err) {
            setLoader(false)
            setMsg({
                status: 'failed',
                message: err.message,
                alertMessage: ""
            })
        }
    }
    return (
        <div className='overlay'>
            <div className='p-c p-1 scale' style={{ width: 'fit-content', height: "fit-content" }}>
                <div className='bg-white br-r-8 ptb-1 plr-1-5' >
                    <div className='d-f f-j-e sm-f-j-c'>
                        <p onClick={() => setPaymentOptions(false)} className='d-f f-a-c f-j-c pointer' style={{ width: '30px', height: '30px', borderRadius: "50px", border: "solid 1px black" }}>&times;</p>
                    </div>
                    <h1 className='txt-a-c b-500 mt-1 primary fs-20px'>Confirm and proceed to payment!</h1>
                    <p className='txt-a-c gray mt-1 gray'>You will be redirected to the payment gateway page. <br /> Please complete the payment within 15 minutes.</p>
                    <div className='d-f mb-1 mt-2 c-gap-2 r-gap-1 f-j-c'>
                        <button onClick={ccAvenueProceedPayment} className='btn pointer plr-1' style={{ width: 'fit-content' }}>Confirm & Pay Now</button>
                        {/* <div onClick={() => { if (true) ccAvenueProceedPayment() }} className='d-f f-col f-j-c br-r-8 pointer paymentHover' style={{ opacity: "1", width: 'fit-content', padding: ".5rem", border: "1px solid #1f316770" }}>
                            <img src={ccAvenuePayImg} alt="" />
                        </div>
                        {/* <div onClick={() => { if (true) { setPaymentOptions(false); razorPrceedPayment() } }} className='d-f f-col f-j-c br-r-8 pointer ' style={{ opacity: ".3", width: 'fit-content', padding: ".5rem", border: "1px solid #1f316770" }}>
                            <img src={razorPayImg} alt="" />
                        </div> */}
                        {/* <div onClick={() => { if (false) { setPaymentOptions(false); upiProceedPayment(); } }} className='d-f f-col f-j-c br-r-8 pointer ' style={{ opacity: ".3", width: 'fit-content', padding: ".5rem", border: "1px solid #1f316770" }}>
                            <img src={hsbcImg} alt="" />
                        </div> */}

                    </div>
                    {/* <a target='_blank' href="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=44d1b861f54c64b7f2d24cea9540f1b15b46609f979be9ee4a424d9c602e01cf6958b9a27533cc0bcf18b357808e7ab9781844d77baec8d8b556d462c960439cbc870e95093a8d4a56828c718db59f42aa962b16780fba48ab34146dd9b597785e07f46499cfdb9c8238caafeed02f3610a06994f15641cef90696d73080256c5c4b1e9f7ca9ceece2cd53f40390a494606543bea76e969c4dcb09fafcbabc316c94fefee73426c875a6aef537eece9e3ba9f479ce295c981c6c326692bcf2e7b9d6c83e39a951f07fc1751488c17d89a49b4fc2133f2f7ba811aad40954f245f61a044361c0f202b5f54a35cd7a6ff49e00c1800d95ebe4d58907211d21e616dc98bd28603b5dc7f17680507be496cf9395d2ac733694deb764a9a96b2fe39c95d536b4a138b5d51dfcc9083e432e4713f5e8c9204572488315682079d90d8d&access_code=AVUM67LC95AH37MUHA">click</a> */}
                    {/* <div>
                        <form method="post" name="redirect" action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction" >
                            <input type="hidden" id="encRequest" name="encRequest" />
                            <input type="hidden" name="access_code" id="access_code" />
                            <input type="submit" />
                        </form>
                    </div> */}
                </div>
            </div>
        </div >
    )
}
function QRcodeModal({ setQrCodeModal, qrCode }) {
    const navigate = useNavigate()
    console.log(qrCode)
    return (
        <div className='overlay'>
            <div className='p-c p-1 scale' style={{ width: 'fit-content', height: "fit-content" }}>
                <div className='bg-white br-r-8 ptb-1 plr-1-5' >
                    <div className='d-f f-j-e sm-f-j-c'>
                        <p onClick={() => setQrCodeModal(false)} className='d-f f-a-c f-j-c pointer' style={{ width: '30px', height: '30px', borderRadius: "50px", border: "solid 1px black" }}>&times;</p>
                    </div>
                    <h1 className='txt-a-c b-500 mt-1'>Ready to Pay? Use Your UPI App.</h1>
                    <p className='txt-a-c gray'>Open your UPI app to scan the QR code or input the code and make the payment.</p>
                    <p className='txt-a-c primary mtb-1' style={{ fontFamily: "Arial, sans-serif" }}>{qrCode.amount.toLocaleString("en-GB", { style: "currency", currency: localStorage.getItem('chargeCurrency') })}</p>
                    <div className='d-f mb-1 f-j-c'>
                        <div className='p-1 br-r-8' style={{ border: '1px solid black' }}>
                            <QRCode value={qrCode.url ? qrCode.url : ""} size={256} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
function OtherMsg() {
    return (
        <div className='overlay'>
            <div className='p-c p-1 scale' style={{ maxWidth: '500px', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={failImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c mt-1'>
                        <p className='primary b-500'>Payment cannot be processed!</p>
                        <p className='' style={{ marginTop: ".5rem" }}>Currently, we are unable to process payments from your region. Please contact an agent to book your tickets. We apologize for the inconvenience.</p>
                        <p className='' style={{ marginTop: ".5rem" }}>
                           Contact our agents <br/>
Email : info@metro.voyage<br/>
Phone : 0117642117<br/>
</p>                        
                        <button className='pointer msg-btn mt-1' onClick={() => window.location.assign('/home')}>ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}