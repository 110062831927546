export async function getData(url) {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        const result = await response.json();
        if (response.status == 200) return result;
        // else if (response.status == 500) window.location.assign('/server-err')
        throw 'error'
    } catch (err) {
        return "";
    }

}

export async function postData(url, params) {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "Application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(params)
        })
        if (response.status == 200) {
            const result = await response.json();
            return result;
        }
        // else if (response.status == 500) window.location.assign('/server-err')

        else if (response.status == 401) {
            localStorage.clear();
            window.location.assign('/')
        }
        // else if (response.status == 500) window.location.assign('/server-err')
    } catch (err) {
        console.log(err)
        return "";
    }

}

// fetch('https://reqbin.com/echo/get/json', {
//   headers: {Authorization: 'Bearer {token}'}
// })

// fetch('https://ipinfo.io/123.231.123.152?token=f798e6830180ff').then(res => res.json())
//     .then(data => console.log(data))
// fetch('https://ipapi.co/currency/').then(res => res.text()).then(data => console.log(data))
export async function getCurrency() {
    // const response = fetch('https://ipapi.co/json/')
    //     .then(res => res.json())
    //     .then(data => 5)
    //     .catch(function (error) {
    //         console.log(error)
    //     });
    try {
        const response = await fetch('https://ipapi.co/json/')
        const result = await response.json();
        if (response.status == 200) return result;
        throw result
    } catch (err) {
        console.log(err)
        return "";
    }
}