import { useState } from "react";
import Process from "./Process";
import SearchVoyages from "../components/common/SearchVoyages";
import { useRef } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
export default function Voyages() {
    const isMob = useMediaQuery({ query: '(max-width:1024px)' })
    const { state } = useLocation();
    const SearchRef = useRef(null)
    const [date, setDate] = useState({
        dateOfTravel: "",
        voyageFrom: "",
        voyageTo: "",
        returnDateOfTravel: "",
        returnVoyageFrom: "",
        returnVoyageTo: ""
    });
    const [openSearch, setOpenSearch] = useState(false)
    const style = {
        display: openSearch ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
        height: '100%',
        backgroundColor: 'rgb(0,0,0,.4)'
    }
    return (
        <>
            <Process date={date} setDate={setDate} setOpenSearch={setOpenSearch} />
            {<div className="sm-p-abs" ref={SearchRef} style={style} onMouseUp={(e) => {
                if (SearchRef.current == e.target) setOpenSearch(false);
            }
            }>
                {(openSearch && !isMob) && <div className="p-c sm-h-100 sm-of sm-w-100" style={{ width: 'fit-content', height: 'fit-content' }}>
                    <SearchVoyages searchedParams={state ? state.fields : ''} />
                </div>
                }
                {
                    (openSearch && isMob) && (<Navigate to="/home" state={state ? state.fields : ''} replace={true} />)
                }

            </div >}
        </>
    )
}