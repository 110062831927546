export default function RefundPolicy() {
  const style = {
    noteBoxe: {
      border: "1px solid",
      borderRadius: "5px",
      padding: "10px",
      margin: "2rem 0 0 0",
      borderColor: "#E76F51",
      backgroundColor: "rgba(231, 111, 81, 0.1)",
    },
  };
  return (
    <div
      className="br-r-1 bg-white m-a h-100"
      style={{
        overflow: "auto",
        maxWidth: "896px",
        padding: "2rem 1.5rem",
        boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <h1 className="fs-20px b-500">
        IndSri Ferry - Connecting Cultures, Bridging Palk Strait
      </h1>
      <div>
        <p style={style.noteBoxe}>
          ⚠️ &nbsp; Providing incorrect information by the customer and or
          travel agents such as wrong passport information, including spellings
          at the time of bookings and visa details- will result in penalty
          charges of{" "}
          <span className="b-700">₹1,000/- or LKR 3,500/- or USD 12</span> time
          of check in for making the corrections!
        </p>
      </div>
      <ul className="">
        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Refund Policy
        </li>
        <li className="mt-1-5">
          For passengers who have booked and confirmed tickets, a refund will be
          provided if tickets are cancelled at least 72 hours before the
          scheduled departure, with a 15% of travel amount administrative charge
          deducted.
        </li>
        <li className="mt-1-5">
          For tickets cancelled within 72 hours of the scheduled departure,
          refund will be provided with a 20% of travel amount administrative
          charge deducted.
        </li>
        <li className="mt-1-5">
          Date change before 72 hours is allowed for one-way trips, fare subject
          to availability and at a cost of 15% of travel amount + Applicable
          taxes.
        </li>
        <li className="mt-1-5">
          Group booking cannot be split for date change or cancellation.
        </li>
        {/* <li className="mt-1-5">
                Date change before 72 hours is allowed for roundtrips, subject to availability and at a cost of USD 25 + Applicable taxes.
                </li> */}
      </ul>
      <ul>
        <br></br>

        <hr></hr>

        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Baggage Policy
        </li>
        <li className="mt-1-5">
          Baggage Allowance: The combined weight of check-In luggage and hand
          luggage should not exceed 23 kg per person.
        </li>
        <li className="mt-1-5">
          Check-In Baggage: Total combined dimensions (length + breadth +
          height) of each piece should not exceed 157 cm/62 in and weight should
          not exceed 18 kg
        </li>
        <li className="mt-1-5">
          Hand Baggage: the size should be: length - 55 cm, width - 35 cm,
          height - 25 cm, and the weight should not exceed 5 kg.
        </li>
        <li className="mt-1-5">
          SUBJECT TO AVAILABILITY OF SPACE, at the time of check in we may allow
          another 27 kg (maximum) as excess weight with an additional payment of
          ₹50+ 18% GST%
        </li>
      </ul>
      <ul>
        <br></br>

        <hr></hr>

        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Visa Requirements
        </li>

        <li
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
          className="mt-1-5 "
        >
          For Indian passport holders entering Sri Lanka:
        </li>
        <li className="mt-1-5">
          ⁠A Passport with 180 days / 6 months validity from the date of Travel
          is required.
        </li>
        <li className="mt-1-5">
          A return ticket by either Sea or Air from Sri Lanka, or an onward
          ticket for onward journey from Sri Lanka is required.
        </li>

        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For Sri Lankan passport holders to enter India:
        </li>
        <li className="mt-1-5">
          ⁠A valid passport with 6 months / 180 days from the date of entry
        </li>
        <li className="mt-1-5">
          A valid visa issued by an Indian High Commission or Embassy is
          required.
        </li>

        <li className="mt-1-5">
          ⁠If it is NEW e-visa they cannot enter the Nagapattinam port for the
          first time. However, if they have already entered India using the same
          e-visa they can enter through Nagapattinam.
        </li>
        <li className="mt-1-5">
          ⁠⁠Return or onward journey to another country.
        </li>

        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For all other International passport Holders to enter India:
        </li>
        <li className="mt-1-5">
          ⁠A valid passport with 6 months / 180 days from the date of entry
        </li>
        <li className="mt-1-5">
          If the passport issuing country is exempted from visa to enter India
          then the passenger can enter India, else you should have a valid visa
          to enter India issued either by the Indian High Commission or Embassy.
        </li>
        <li className="mt-1-5">
          ⁠First time entry with an e-visa India is not permitted to enter
          through Nagapattinam. However subsequent entries are allowed.
        </li>
        <li className="mt-1-5">
          All tourists or visitors requires a valid return or onward journey
          ticket.
        </li>

        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For all other International passport holders to enter Sri Lanka:
        </li>
        <li className="mt-1-5">
          A valid passport with 6 months / 180 days from the date of entry
        </li>
        <li className="mt-1-5">
          If the passport issuing country is in the visa on arrival category,
          there is no need to have a visa to enter Sri Lanka otherwise you
          should apply get a visa to enter Sri Lanka.
        </li>
        <li className="mt-1-5">
          ⁠All tourists or visitors require a valid return or onward journey
          ticket.
        </li>
      </ul>
      <div className="txt-a-c mt-2">
        {/* <button className="btn pointer" onClick={() => setShowTermsConditions(false)}>Continue</button> */}
      </div>
    </div>
  );
}
