import { useNavigate } from "react-router-dom";
export default function Discount({setOffer,img,name}) {
    const navigate = useNavigate();
    return (
        <div className='overlay' style={{ zIndex: 3, overflow: "scroll" }}>
            <div id='alertMsg-wrapper' className='p-c txt-a-c' style={{ width: "fit-content" }}>
                <span onClick={()=>{
                            // navigate('/home');
                            if(name == 'dis' ){
                                sessionStorage.setItem("clickOffer",2);
                                setOffer(2)
                            }else{
                                sessionStorage.setItem("clickOffer2",2);
                                setOffer(2)
                            }
                            
                            }} className='white pointer' style={{ fontSize: "2rem" }}>&times; </span>
                <div className='br-r-8'>
                    <div className='txt-a-c'>
                        <img id='alertMsg' onClick={()=>{
                            navigate('/home');
                            if(name == 'dis' ){
                                sessionStorage.setItem("clickOffer",2);
                                setOffer(2)
                            }else{
                                sessionStorage.setItem("clickOffer2",2);
                                setOffer(2)
                            }
                            }} className='pointer' src={img} alt="" height="700px" />
                    </div>
                </div>
            </div>
        </div>
    )
}