import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseEye, OpenEye } from "../icons/eye";
import { useState } from "react";
import Loader from "../icons/Loader";
import successImg from '../../assets/images/success.png';
import failImg from '../../assets/images/fail.png';
import questionImg from "../../assets/images/question.png"

export default function Invoice() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showConfirm, setShowConfirm] = useState(true);
    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState({
        status: "",
        message: '',
    });
    const [country, setCountry] = useState(1);
    const [field, setField] = useState({
        bookingId: state ? state : JSON.parse(localStorage.getItem('bookingId')),
        isTaxRegistered: 1,
        businessEntity: "",
        taxN: "",
        vatN: "",
        email: "",
        businessRegisterIn: 1
    })
    const submit = field.businessEntity && field.email
    async function handleSumbit(e) {
        e.preventDefault();
        setLoader(true);
        field.businessRegisterIn = country
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/Pax/storeUserDetails', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(field)
            });
            console.log(response)
            const result = await response.json();
            if (response.status == 200) {
                setMsg({
                    status: "success",
                    message: "Your TAX invoice generated successfully!",
                    subTitle: "Continue to Book your Preferred Seat for Departure."
                })
                setTimeout(() => {
                    setMsg({ status: "", message: "" });
                    navigate('/seat-selection', { state: state })
                }, 5000)

            }
            else {
                throw result
            }
        } catch (err) {
            setLoader(false);
            setMsg({
                status: "failed",
                message: "Failed"
            })
        }
        finally {
            setLoader(false);
            setTimeout(() => setMsg({ status: "", message: "" }), 5000)
        }
    }
    function countryChange() {
        console.log('hi')
    }
    if (showConfirm) return <ConfirmInvoice setShowConfirm={setShowConfirm} bookingId={state} />
    else return (
        <div className="p-r w-100 d-f f-j-c f-a-c" style={{ height: 'calc(100vh - 98px)' }}>
            <div className="fade p-1-5 p-r d-f br-r-1 w-100 h-100" style={{ maxWidth: "max-content", height: "fit-content", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="d-f f-col f-j-c f-a-c r-gap-2 m-a">
                    <h1 className="fs-20px b-500">Generate your VAT / GST invoice to your email</h1>
                    <form onSubmit={handleSumbit}>
                        <div className="d-f f-col r-gap-1 w-100">
                            <div>
                                <label htmlFor="" className="b-500 star">Business Entity Name</label><br />
                                <input onChange={(e) => setField({ ...field, businessEntity: e.target.value })} style={{ padding: "14px 24px", maxWidth: '448px' }} className="mt-8px fs-14px input-gray gray w-100 h-48 br-r-8 br-n" type='text' name="businessEntity" placeholder="Business Entity Name" required />
                            </div>
                            <div>
                                <label htmlFor="" className="b-500 star">Business Country</label>
                            </div>
                            <div className="f-spb d-f">
                                <div className="mr-2">
                                    <input onChange={() => setCountry(1)} style={{ width: "20px", height: "20px" }} id="india" type="radio" name="type" className="mr-1" value='1' defaultChecked={true} />
                                    <label htmlFor="india" className="sm-fs-14px">India</label>
                                </div>
                                <div>
                                    <input onChange={() => setCountry(2)} style={{ width: "20px", height: "20px" }} id="srilanka" className="mr-1" type="radio" name="type" value='2' />
                                    <label htmlFor="srilanka" className="sm-fs-14px">SriLanka</label>
                                </div>
                                <div>
                                    <input onChange={() => setCountry(3)} style={{ width: "20px", height: "20px" }} id="other" className="mr-1" type="radio" name="type" value='3' />
                                    <label htmlFor="other" className="sm-fs-14px">Other</label>
                                </div>
                            </div>
                            {country !== 3 && <div className="fade">
                                <label htmlFor="" className="b-500">{`${country == 1 ? 'GST' : 'VAT'}  Registration Number`}</label><br />
                                <input onChange={(e) => setField({ ...field, taxN: e.target.value })} name={country == 1 ? 'taxN' : "vatN"} placeholder={`${country == 1 ? 'GST' : 'VAT'}  Registration Number`} style={{ padding: "14px 24px", maxWidth: '448px' }} className="mt-8px fs-14px input-gray gray w-100 h-48 br-r-8 br-n" type='text' />
                            </div>}
                            <div>
                                <label htmlFor="" className="b-500 star">Email to Receive TAX Invoice</label><br />
                                <input onChange={(e) => setField({ ...field, email: e.target.value })} style={{ padding: "14px 24px", maxWidth: '448px' }} className="mt-8px fs-14px input-gray gray w-100 h-48 br-r-8 br-n" type='email' name="email" placeholder="Email Address" required />
                            </div>
                        </div>
                        <div className="d-f c-gap-2 f-j-c f-wrap sm-r-gap-1 mt-2">
                            <button className="btn pointer" disabled={!submit} style={{ width: "196px", opacity: submit ? 1 : '.4' }}>Continue</button>
                            <button className="br-btn pointer" style={{ width: "196px" }} onClick={() => setShowConfirm(true)} >Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            {msg.message && <div className="overlay"><Msg msg={msg} /></div>}
            {loader && <div className='overlay'>
                <Loader />
            </div>}
        </div >

    )
}

function ConfirmInvoice({ setShowConfirm, bookingId }) {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    setTimeout(() => setShow(false), 5000)
    return (
        <div className="p-c p-2 w-100 h-100 d-f f-j-c f-a-c sm-p-1">
            {show && <PaymentSuccess />}
            <div className="fade p-r d-f br-r-1 w-100 h-100" style={{ maxWidth: "896px", maxHeight: "460px", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="d-f f-col f-j-c f-a-c r-gap-2 m-a  w-100 sm-p-1 p-1-5">
                    <img src={questionImg} alt="" style={{ maxWidth: "fit-content", width: '100%', height: "fit-content" }} />
                    <div>
                        <h1 className="b-500 txt-a-c">Do you need Tax Invoice?</h1>
                        <p className="txt-a-c gray">Get your Tax invoice to your email.</p>
                    </div>
                    <div className="d-f c-gap-2 f-j-c f-wrap sm-r-gap-1 w-100">
                        <button onClick={() => setShowConfirm(false)} className="btn pointer" style={{ maxWidth: "256px", width: "100%" }}>I need Tax Invoice</button>
                        <button className="br-btn pointer" style={{ maxWidth: "256px", width: "100%" }} onClick={() => navigate('/seat-selection', { state: bookingId })} >No Need & Continue</button>
                    </div>
                </div>
            </div>
        </div >
    )
}
function Msg({ msg }) {
    return (
        <div className='overlay'>
            <div className='p-c p-1' style={{ maxWidth: 'fit-content', height: "fit-content" }}>
                <div className='p-1 br-r-8 bg-white'>
                    <div className='txt-a-c'>
                        <img src={msg.status == "success" ? successImg : failImg} alt="" width="80px" />
                    </div>
                    <div className='txt-a-c mt-1'>
                        <p className='primary b-500'>{msg.message}</p>
                        <p className="gray" style={{ marginTop: ".5rem" }}>{msg.subTitle}</p>
                        {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
function PaymentSuccess() {
    return (
        <div className="overlay">
            <div className="p-c p-1" style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className="p-1 br-r-12 bg-white">
                    {/* <h1 className="red fs-20px b-500">Error . . .</h1> */}
                    <div className='txt-a-c'><img src={successImg} alt="" width="80px" /></div>
                    <h1 className="fs-20px b-500 primary txt-a-c mt-1">You have made the payment successfully!</h1>
                    <p className="txt-a-c gray" style={{ marginTop: ".5rem" }}>
                        Few steps more to complete your booking & get your eTicket.
                    </p>
                </div>
            </div>
        </div>
    )
}