import { postData } from "./utils";

export async function getOrderId(params) {
    const url = process.env.REACT_APP_DOAMIN + '/booking/ProceedToPayment';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}

export async function verifyPayment(params) {
    const url = process.env.REACT_APP_DOAMIN + '/payment/PaymentVerification';
    const data = await postData(url, params);
    if (data) return data;
    return '';
}