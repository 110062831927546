export default function PrivacyPolicy() {
    return (
        <div className="sm-p-1 br-r-1 p-1 m-a" style={{ maxWidth: "896px", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <h1 className="fs-20px b-500">Privacy Policy for IndSri Ferry Services Pvt Ltd - Ferry Ticket Booking System</h1>
            <p className="mt-1-5">Effective Date: <span className="b-600">15th January 2024</span></p>
            <p className="mt-1-5">Welcome to IndSri Ferry Services Pvt Ltd a ferry ticket booking service for travel between India and Sri Lanka. We are committed to protecting the privacy and security of our customers' personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal information when you use our booking system.</p>
            <h1 className="b-700 mt-1-5">Information Collection</h1>
            <p className="mt-1"><span className="b-600">Personal Information:</span> We collect personal information that you provide to us directly when booking a ticket. This includes your name, contact Information, payment details, and travel documentation.</p>
            <p className="mt-1"><span className="b-600">Usage Data:</span>We automatically collect information on how our service is accessed and used, such as your computer's Internet Protocol (IP) address, browser type .</p>
            <h1 className="b-700 mt-1-5">Use of Information</h1>
            <p className="mt-1">We use your personal information to process your ferry ticket bookings, manage your travel arrangements, process payments, and communicate with you about your booking. We may use your information to improve our services and offer personalised experiences.</p>
            <h1 className="b-700 mt-1-5">Sharing of Information</h1>
            <p className="mt-1">We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer service.</p>
            <p className="mt-1">We do not sell or rent personal information to third parties for their markeing purposes without your explicit consent.</p>
            <h1 className="b-700 mt-1-5">Data Security</h1>
            <p className="mt-1">We implement appropriate security measures to protect against unauthorised access, alteration, disclosure, or destruction of your personal information.</p>
            <h1 className="b-700 mt-1-5">Cookies and Tracking Technologies</h1>
            <p className="mt-1">We use cookies and similar tracking technologies to track activity on our service and hold certain information to enhance your user experience.</p>
            <h1 className="b-700 mt-1-5">Your Rights</h1>
            <p className="mt-1">You have the right to access, update, or delete the personal information we hold about you. You have the right to opt out of receiving promotional communications from us.</p>
            <h1 className="b-700 mt-1-5">Changes to This Privacy Policy</h1>
            <p className="mt-1">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <h1 className="b-700 mt-1-5">Contact Us</h1>
            <p className="mt-1">If you have any questions about this Privacy Policy, please contact us at <span className="primary">bookings@sailindsri.com</span></p>
            <p className="mt-1-5">IndSri Ferry Services Pvt Ltd respects your privacy and is commited to protecting it through our compliance with this policy.</p>
        </div>
    )
}